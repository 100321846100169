
import React                  from 'react';
import PropTypes              from 'prop-types';
import { AlertDialog }        from 'react-onsenui';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';

import * as actions from 'src/store/actions';

import './ChooseLangDialog.scss';
import '../genericModal.scss';


export const COMPONENT_KEY = 'ChooseLangDialog';
export const CONTAINER_DOM_ID = 'choose-lang-dialog-container';


const DEFAULT_DIALOG_PROPS = {
    isCancelable: true,
    animation   : 'default', // or 'none', no other choice sadly
};

/**
 * Display a dialog (modal) allowing user to change current language for the whole app
 * @param  {object} props
 */
const ChooseLangDialog = props => {

    let currentLangId = props.labels.id;

    /**
     * Actions when user has clicked a flag
     * @param  {string} lang
     */
    function handleFlagSelected(lang) {
        if (lang !== currentLangId) {
            props.actions.setLanguage(lang);
        }
        props.actions.hideChooseLangDialog();
    }

    /**
     * Return an array of button-like flags for every supported language
     * @return {array}
     */
    const getLangEls = () => (
        props.supportedLangs.map(lang => {
            let cssClasses = ['flag-select', 'flag-'+lang];

            if (lang === currentLangId) {
                cssClasses.push('is-current-lang');
            }
            return (
                <span key={ lang }
                      title={ props.labels.lang[lang] }
                      onClick={() => { handleFlagSelected(lang); }}
                      className={ cssClasses.join(' ') }></span>
            );
        })
    );

    let dialogProps = Object.assign({}, DEFAULT_DIALOG_PROPS, {
        isOpen   : props.isOpen,
        onCancel : props.actions.hideChooseLangDialog, // important, to update `isOpen` value in all cases
    });

    return (
        <AlertDialog {...dialogProps}>
            <div className="generic-modal">
                <div className="title-font">{props.labels.common.chooseLang}</div>

                <div id="cl-langs">{ getLangEls() }</div>

                <div className="generic-btn-container">
                    <div className="generic-btn-cancel"
                       onClick={props.actions.hideChooseLangDialog}>{props.labels.common.cancel}</div>
                </div>
            </div>
        </AlertDialog>
    );
};

ChooseLangDialog.propTypes = {
    isOpen     : PropTypes.bool.isRequired,
    labels     : PropTypes.object.isRequired,
    actions    : PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChooseLangDialog);
