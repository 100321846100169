
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    DATA_TYPE_AIRCRAFTS,
    DATA_TYPE_EVENTS,
    DATA_TYPE_EXHIBITOR_CATEGORIES,
    DATA_TYPE_EXHIBITORS
} from 'data/config/dataConfig';

import { PRESS_PROFILE } from 'data/config/profiles';

import { EXHIBITOR_PAGE_KEY, LIST_PAGE_KEY, DOCUNIT_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailCountry      from 'src/components/detail-country/DetailCountry';
// import DetailAircrafts    from 'src/components/detail-aircrafts/DetailAircrafts';
import DetailDataList     from 'src/components/detail-data-list/DetailDataList';
// import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle   from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailNewProducts  from 'src/components/detail-newproducts/DetailNewProducts';
import DetailPlaces       from 'src/components/detail-places/DetailPlaces';
import DetailSocial       from 'src/components/detail-social/DetailSocial';
import UseRemainingHeight from 'src/components/use-remaining-height/UseRemainingHeight';
import Description        from 'src/components/fiche/Description';
import PhoneRow           from 'src/components/fiche/PhoneRow';
import EmailRow           from 'src/components/fiche/EmailRow';
import WebsiteRow         from 'src/components/fiche/WebsiteRow';
// import LinkButton         from 'src/components/link-button/LinkButton';
import ShareButton        from 'src/components/share-button/ShareButton';
import NoteButton         from 'src/components/notes/NoteButton';

import Url                from 'src/components/url/Url';
import CTAButton          from 'src/components/cta-button/CTAButton';
import { formatDateAndTime } from 'src/core/Lang';


class ExhibitorContent extends PureComponent {

    setScroller = ref => {
        // Used by parent component
        this.scroller = ref;
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_EXHIBITORS,
            this.props.isFavorite
        );
    }


    showEvents = () => {
        this.props.actions.navigateToItems(
            this.props.item.references[DATA_TYPE_EVENTS],
            DATA_TYPE_EVENTS,
            this.props.item.id,
            DATA_TYPE_EXHIBITORS);
    }

    showAircrafts = () => {
        this.props.actions.navigateToItems(
            this.props.item.references[DATA_TYPE_AIRCRAFTS],
            DATA_TYPE_AIRCRAFTS,
            this.props.item.id,
            DATA_TYPE_EXHIBITORS);
    }

    onClickOnCategory = cat => {

        if (Array.isArray(cat.exhibitors) && cat.exhibitors.length > 0) {
            // Display content
            this.props.actions.navigate(LIST_PAGE_KEY, {
                inputs: [{
                    parentId  : cat.id,
                    parentType: DATA_TYPE_EXHIBITOR_CATEGORIES,
                    dataType  : DATA_TYPE_EXHIBITORS,
                }],
            });
        }
        else {
            // Display sub-categories
            this.props.actions.navigate(LIST_PAGE_KEY, {
                inputs: [{
                    id: cat.id,
                    dataType: DATA_TYPE_EXHIBITOR_CATEGORIES,
                }],
            });
        }
    }

    sendAppointmentRequest = () => {
        this.props.actions.sendAppointmentRequest(
            this.props.item.id,
            DATA_TYPE_EXHIBITORS,
            this.props.item.original_id,
        );
    }

    renderCategory = (cat, level) => (
        <>
            <div key={cat.id}
                 onClick={() => { this.onClickOnCategory(cat); }}
                 className={`activities-content color-link-blue cat-level-${level}`}>

                <span>{ cat.title }</span>
                <span className="list-el-counter"><span>{cat.counter}</span></span>

                <div className="prop-right-fleche">
                    <span className="fa fa-chevron-right fleche" />
                </div>
            </div>

            { Array.isArray(cat.cats) &&
                cat.cats.map(subCat => this.renderCategory(subCat, level+1))
            }
        </>
    )

    render() {
        let lump = this.props.item.lump;

        const
            title       = this.props.item.title,
            logoUrl     = this.props.item.logo_file_name,
            description = this.props.item.description,
            references  = this.props.item.references,
            hasAddress  = this.props.item.address || this.props.item.postal_code || this.props.item.city || this.props.item.references.country,
            hasSocial   = (lump.social && (lump.social.tw || lump.social.fb || lump.social.ln)),
            hasDocunits    = lump.docunits
                && lump.docunits.length > 0
                && references.docunits
                && references.docunits.length > 0
                && this.props.profile === PRESS_PROFILE,

            catHierarchy = this.props.item.references && this.props.item.references.catHierarchy ? this.props.item.references.catHierarchy : null,

            hasContactsBlock = (lump.contacts && lump.contacts.length > 0)
                                    || this.props.item.phone
                                    || this.props.item.email
                                    || this.props.item.website
                                    || hasAddress
                                    || hasSocial
                                    || (this.props.appointmentRequestStatus.isEnabled && lump.networking);

        return (
            <div className="fiche exhibitor content-font" style={{ height: this.props.height }}>

                {/* title */}
                <DetailFixedTitle
                        image={lump && lump.focus !== undefined ? lump.focus : null}
                        image2={lump && lump.focus2 !== undefined ? lump.focus2 : null}
                        image3={lump && lump.focus3 !== undefined ? lump.focus3 : null}
                        title={title}
                        isFav={this.props.isFavorite}
                        onFavClick={this.onClickOnFavIcon}
                        labels={this.props.labels}>
                    <NoteButton
                          itemTitle={this.props.item.title}
                          itemId={this.props.item.id}
                          dataType={DATA_TYPE_EXHIBITORS}
                          hasNote={this.props.hasNote}
                          labels={this.props.labels}
                          actions={this.props.actions} />
                    <ShareButton
                        name={this.props.getPageTitle(this.props.item, this.props.labels)}
                        queryString={this.props.queryString}
                        description={description}
                        image={logoUrl}
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        pageKey={EXHIBITOR_PAGE_KEY} />

                </DetailFixedTitle>

                <UseRemainingHeight ref={this.setScroller}>

                    <div className="content">
                        <div className="all-informations content-font">

                            <Description
                                logoFileName={logoUrl}
                                description={description}
                                isLogoFullWidth={this.props.isLogoFullWidth} />

                            {/* Counters */}
                            <div>
                                {/* places */}
                                <DetailPlaces
                                    labels={this.props.labels}
                                    actions={this.props.actions}
                                    id={this.props.item.id}
                                    originalId={this.props.item.original_id}
                                    entityType={DATA_TYPE_EXHIBITORS}
                                    places={this.props.item.references.places}
                                    textPlaces={this.props.item.exhibitor_place_text}
                                    />
                            </div>

                            {/* newproducts */}
                            <DetailNewProducts
                                items={this.props.item.references.newproducts}
                                actions={this.props.actions}
                                labels={this.props.labels}
                                pageKey={EXHIBITOR_PAGE_KEY} />

                            {/* events */}
                            <DetailDataList
                                dataType={DATA_TYPE_EVENTS}
                                items={this.props.item.references.events}
                                actions={this.props.actions}
                                labels={this.props.labels}
                                pageKey={EXHIBITOR_PAGE_KEY} />

                            {/* aircrafts */}
                            <DetailDataList
                                dataType={DATA_TYPE_AIRCRAFTS}
                                items={this.props.item.references.aircrafts}
                                actions={this.props.actions}
                                labels={this.props.labels}
                                pageKey={EXHIBITOR_PAGE_KEY} />

                            {/* contacts */}
                            { hasContactsBlock &&

                                <div>
                                    <div className="fiche-separator">{this.props.labels.exhibitor.contacts}</div>

                                    { /* Appointment request */ }
                                    { lump.networking && this.props.appointmentRequestStatus.isEnabled && !this.props.appointmentRequestStatus.date &&
                                        <CTAButton
                                            isEnabled={this.props.appointmentRequestStatus.ongoing !== true}
                                            label={this.props.labels.appointment.button}
                                            action={this.sendAppointmentRequest} />
                                    }
                                    { lump.networking && this.props.appointmentRequestStatus.isEnabled && this.props.appointmentRequestStatus.date &&
                                        <div className="appointment-request-sent">{
                                            this.props.labels.appointment.sent(
                                                formatDateAndTime(this.props.appointmentRequestStatus.date),
                                                this.props.appointmentRequestStatus.userEmail,
                                                this.props.appointmentRequestStatus.userPhone)
                                        }</div>
                                    }

                                    <DetailSocial data={lump.social} actions={this.props.actions} />

                                    { lump.contacts && lump.contacts.length > 0 &&
                                        <div className="free-row">
                                            <div className="prop-img">
                                                <div className="prop-left">
                                                    <div className="fiche-contact-icon">
                                                        <span className="fa fa-user" />
                                                    </div>
                                                </div>
                                                <div className="prop-right">
                                                    { lump.contacts.map((entry, index) => (
                                                        <div key={index}>
                                                            <span className="contactName" dangerouslySetInnerHTML={{ __html: entry.n }} />
                                                        </div>
                                                    )) }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <PhoneRow phone={this.props.item.phone} />

                                    <WebsiteRow
                                        website={this.props.item.website}
                                        actions={this.props.actions} />

                                    <EmailRow email={this.props.item.email} />

                                    {/* address */}
                                    { hasAddress &&
                                        <div className="free-row">
                                            <div className="prop-img">
                                                <div className="prop-left">
                                                    <div className="fiche-contact-icon">
                                                        <span className="fa fa-university" />
                                                    </div>
                                                </div>
                                                <div className="prop-right">
                                                    { this.props.item.address     && <div dangerouslySetInnerHTML={{ __html: this.props.item.address }} /> }
                                                    { this.props.item.postal_code && <div dangerouslySetInnerHTML={{ __html: this.props.item.postal_code }} /> }
                                                    { this.props.item.city        && <div dangerouslySetInnerHTML={{ __html: this.props.item.city }} /> }
                                                    { this.props.item.references.country && <div dangerouslySetInnerHTML={{ __html: this.props.item.references.country.title }} /> }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>

                            } {/* end contacts */}


                            {/* entities */}
                            { lump.entities && lump.entities.length > 0 &&
                                <div>
                                    <div className="fiche-separator">{this.props.labels.exhibitor.entities}</div>
                                    <div>
                                        <div className="prop-img">
                                            <div className="prop-left">
                                                <div className="fiche-contact-icon">
                                                    <span className="fa fa-university" />
                                                </div>
                                            </div>
                                            <div className="prop-right">
                                                { lump.entities.map((entry, index) => (
                                                    <div key={index} className="entites-content">
                                                        { entry.title   && <div className="addressName">{entry.title}</div> }
                                                        { entry.type    && <div className="typeName">{entry.type}</div> }
                                                        { entry.address && <div className="addressName">{entry.address}</div> }
                                                        { entry.phone &&
                                                            <div className="phoneName">
                                                                <a className="link color-link-blue" href={'tel:'+entry.phone}>{entry.phone}</a>
                                                            </div>
                                                        }
                                                        { entry.email &&
                                                            <div className="emailName">
                                                                <a className="link color-link-blue" href={'mailto:'+entry.email}>{entry.email}</a>
                                                            </div>
                                                        }
                                                        { entry.website &&
                                                            <div className="websiteName">
                                                                <Url callback={this.props.actions.linkClicked}
                                                                     href={entry.website}
                                                                     label={entry.website} />
                                                            </div>
                                                        }
                                                        <div className="separatorEnt"></div>
                                                    </div>
                                                )) }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* <div className="fiche-separator">{this.props.labels.exhibitor.agentCountries}</div> */}
                            <DetailCountry country={lump.agent_countries} />

                            {/* brands */}
                            { this.props.item.brands &&
                                <div>
                                    <div className="fiche-separator">{this.props.labels.exhibitor.brands}</div>
                                    <div className="free-row">
                                        <div className="prop-img">
                                            <div className="prop-left">
                                                <div className="fiche-contact-icon">
                                                    <span className="fa fa-star-o" />
                                                </div>
                                            </div>
                                            <div className="prop-right">
                                                <span>{ this.props.item.brands }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                            {/* Categories */}
                            { Array.isArray(catHierarchy) &&
                                catHierarchy.map(catSys => (
                                    <div key={catSys.id}>
                                        <div className="fiche-separator">{catSys.title}</div>
                                        <div className="activity free-row">
                                            <div className="prop-img">
                                                <div className="prop-left">
                                                    <div className="fiche-contact-icon">
                                                        <span className="fa fa-tag" />
                                                    </div>
                                                </div>
                                                <div className="prop-right activites flex-ellipsis">
                                                    { catSys.cats.map(cat => this.renderCategory(cat, 2)) }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }

                            {/* Documents */}
                            { hasDocunits &&
                                <div>
                                    <div className="fiche-separator">{this.props.labels.docunit.presskit}</div>
                                    {references.docunits.map(docunit => (
                                        <div key={docunit.id} className="activity free-row">
                                            <div className="prop-img">
                                                <div className="prop-left">
                                                    <div className="fiche-contact-icon">
                                                        <span className="fa fa-newspaper-o" />
                                                    </div>
                                                </div>
                                                <div className="prop-right cats flex-ellipsis">
                                                    <div
                                                        onClick={() => {
                                                            this.props.actions.navigate(
                                                                DOCUNIT_PAGE_KEY,
                                                                { id: docunit.id }
                                                            )
                                                        }}
                                                        className="activities-content color-link-blue"
                                                    >
                                                        <span>{ docunit.title }</span>
                                                        <div className="prop-right-fleche">
                                                            <span className="fa fa-chevron-right fleche" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                        </div> {/* end of .allInformations */}
                    </div>
                </UseRemainingHeight>
            </div>
        );
    }

};

ExhibitorContent.propTypes = {
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    appointmentRequestStatus: PropTypes.object.isRequired,
    isLogoFullWidth: PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    getPageTitle: PropTypes.func.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
    height      : PropTypes.number, // prop set from AppToolbar
};

export default ExhibitorContent;
