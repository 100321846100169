
import ons from 'onsenui';
import { get as getLabels } from 'src/core/Lang';
import { isAndroid } from './browser';


/**
 * @param  {object} opts such as:
 * {
 *   title: string
 *   text: string
 *   yesBtnLabel: string - default: yes
 *   noBtnLabel: string - default: no
 *   anywayCb: function - callback executed is both case, before the other callbacks
 *   yesCb: function
 *   noCb: function
 * }
 */
export default function(opts) {
    if (!opts) {
        return;
    }

    let labels = getLabels();

    let yesBtnLabel = opts.yesBtnLabel || labels.common.yes,
        noBtnLabel = opts.noBtnLabel || labels.common.no;

    ons.notification.confirm(opts.message || ' ', {

        title: opts.title,

        buttonLabels: isAndroid() ? [yesBtnLabel, noBtnLabel] : [noBtnLabel, yesBtnLabel],

        callback: function(selectedBtnIndex) {
            if (typeof opts.anywayCb === 'function' ){
                opts.anywayCb();
            }

            if ((selectedBtnIndex === 0 && isAndroid())
                    || (selectedBtnIndex === 1 && !isAndroid())) {
                // confirmed
                if (typeof opts.yesCb === 'function' ){
                    opts.yesCb();
                }
            } else {
                // cancelled
                if (typeof opts.noCb === 'function' ){
                    opts.noCb();
                }
            }
        }
    });
};
