import { NEWPRODUCT_PAGE_KEY } from 'src/pages/pagesKeys.js';

import NewProductPage        from 'src/pages/new-product/NewProductPage';
import newProductPageReducer from 'src/pages/new-product/newProductPageReducer';

export default {
    key      : NEWPRODUCT_PAGE_KEY,
    path     : '/newproduct',
    component: NewProductPage,
    elId     : 'newproduct-page',
    getReduxReducer: (state, action) => newProductPageReducer(state[NEWPRODUCT_PAGE_KEY], action),
};
