import config from 'data/config/config';

import * as UserDataService from 'src/core/user-data/UserDataService';

import * as LoginPersistence from 'src/core/login/LoginPersistence';
import { getParameterByName } from 'src/core/util/JsTools';
import { getBindedActions } from 'src/store/bindedActions';

import * as LoginWsNodeBackend from 'src/core/webservices/nodebackend/LoginWS';
import * as LoginWsTaiga from 'src/core/webservices/taiga/LoginWS';
import { TAIGA, NODEBACKEND } from 'src/core/webservices/WsProviders';


const LOG_PREF = '[LoginService] ';

const QUERY_PARAM_TOKEN = 'token';

export const ERRORS = {
    LOGIN  : 'LOGIN_WS_ERROR',
    NETWORK: 'LOGIN_WS_NETWORK_ERROR',
};


export const isSessionValid = () => (
    LoginPersistence.getToken() !== null &&
    (!LoginPersistence.getTokenExpiry() ||  LoginPersistence.getTokenExpiry() > new Date().getTime())
);


/**
 * Inner-module responsible for AUTO-LOGIN
 */
const AutoLogin = (function() {

    let intervalId;


    function start() {
        if (intervalId) {
            // Already running
            return;
        }
        console.log(LOG_PREF + ' autologin: start');
        intervalId = setInterval(relogin, config.LOGIN.autologinDelay * 1000);
    }

    function stop() {
        if (intervalId) {
            console.log(LOG_PREF + ' autologin: stop');
            window.clearInterval(intervalId);
            intervalId = null;
        }
    }

    return {
        init: init,
        start: start,
        stop: stop,
    };
})();


export function onUserLogIn() {
    if (typeof config.LOGIN.autologinDelay !== 'number') {
        console.warn(LOG_PREF + `Autologin disabled ('autologinDelay' not specified or not a number)`);
    } else {
        AutoLogin.start();
    }
}


/**
 * Call login API if the user information is available
 */
function relogin() {
    if (isSessionValid()) {
        console.log(LOG_PREF + 'Autologin: about to renew token');
        let account = LoginPersistence.getAccount();
        if (account) {
            login(account.username, account.password);
        }
    } else {
        logout();
    }
}


export function init() {
    // First look for the token in query parameters
    let userToken = getParameterByName(QUERY_PARAM_TOKEN);
    if (userToken) {
        LoginPersistence.setToken(userToken);
        UserDataService.getUserDataFromAPI();
    } else {
        // else will look in localstorage (previous sessions)
        relogin();
    }
}

function updateToken(value) {
    LoginPersistence.setToken(value);
    // TODO ?
    //getBindedActions().setLoginIdentification(value);
}


/**
 * Call the API to log the user in
 * @param  {string} username
 * @param  {string} password
 */
export function login(username, password) {
    let account = {
        username: username,
        password: password
    };
    LoginPersistence.setAccount(account);

    switch (config.LOGIN.PROVIDER) {

        case NODEBACKEND:
            LoginWsNodeBackend.performLogin(account, loginCallback);
            break;

        case TAIGA:
            LoginWsTaiga.performLogin(account, loginCallback);
            break;

        default:
            console.error(LOG_PREF+'Unexpected web service provider: '+config.LOGIN.PROVIDER);
    }

}

function loginCallback(errorCode, token, userData) {
    if (!errorCode) {
        updateToken(token);
        UserDataService.updateUserDataFromApiData(userData)
        getBindedActions().setLoginStatus(true, userData, null);
    }
    else {
        switch (errorCode) {
            case ERRORS.LOGIN:
                getBindedActions().setLoginStatus(false, null, errorCode);
                updateToken(null);
                break;

            case ERRORS.NETWORK:
                // TODO ?
                /*if (isSessionValid()) {
                    getBindedActions().setLoginIdentification(LoginPersistence.getToken());
                }*/
                break;

            default: console.error(LOG_PREF+'Unexpected error code: '+errorCode);
        }
        getBindedActions().setLoginStatus(false, null, errorCode);
    }
}


/**
 * Set the user as logged out (no API call)
 */
export function logout() {
    if (LoginPersistence.getToken() === null) {
        // Already logged out
        return;
    }

    updateToken(null);

    let account = LoginPersistence.getAccount();
    if (account) {
        account.password = '';
        LoginPersistence.setAccount(account);
    }

    AutoLogin.stop();
    UserDataService.clear();
}
