
import React from 'react';
import PropTypes from 'prop-types';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import config from 'data/config/config';
import { VALID_DATA_TYPES } from 'data/config/dataConfig';


import { convertDataTypeToMobigeoType } from 'src/core/data-and-assets/Db';

import {
    elementPropsGetters,
    onClickOnLi,
    onClickOnNote,
    HIGHLIGHTED_PROPS,
    ROW_BACKGROUND_COLOR_ON_CLICK,
} from 'data/config/listConfig';

// App modules
import ListElement, { ORIGINAL_PROP_PREFIX } from './ListElement';
import ListLoaderElement from './ListLoaderElement';
import Highlight from './Highlight';
import NoResult  from 'src/components/no-result/NoResult';
import { findParentNode } from 'src/core/util/DomTools';

import { MAP_PAGE_KEY } from 'src/pages/pagesKeys';

import * as Notes from 'src/core/notes/Notes';

import * as actions from 'src/store/actions';

import './List.scss';


/**
 * Return helpers (functions) to get props for list elements
 * @param  {string} dataType
 * @param  {object} favorites
 * @param  {boolean} displayFavorites
 * @return {object}
 */
function getElementHelpers(dataType, favorites, pageKey, displayFavorites) {

    const COMMON_HELPERS = {
        'key'      : row => row.id,
        'data-id'  : row => row.id,
        'data-type': () => dataType,
    };

    if (displayFavorites) {
        COMMON_HELPERS.isFavorite = row => {
            return favorites && favorites.indexOf(row.id) !== -1;
        };
    }

    let helpers = elementPropsGetters(dataType, pageKey);
    if (!helpers) {
        console.error(`Type ${dataType} is not handled yet`);
    } else {
        return Object.assign({}, COMMON_HELPERS, helpers);
    }
}


/**
 * LIST COMPONENT
 */
class List extends React.PureComponent {

    state = {};

    /**
     * Apply helpers to generate attributes value
     * @param  {*}      item
     * @param  {object} helpers
     * @param  {RegExp} highlightRegexp
     * @return {object}
     */
    getRowProps(item, helpers, highlightRegexp) {

        if (item.isSeparator === true) {
            return item;
        }

        return Object.keys(helpers).reduce((elProps, key) => {
            elProps[key] = helpers[key](item);

            // Apply highlight
            if (highlightRegexp &&
                    HIGHLIGHTED_PROPS.indexOf(key) !== -1 &&
                    elProps[key]) {

                // Keep a copy of original value
                elProps[ORIGINAL_PROP_PREFIX+key] = elProps[key];

                elProps[key] = Highlight.apply(
                    elProps[key],
                    highlightRegexp
                );
            }
            return elProps;
        }, {});
    }


    /**
     * Generate content
     * @return {array}
     */
    getElements() {

        if (this.props.isPending === true) {
            return <ListLoaderElement labels={this.props.labels} />;
        }

        const highlightRegexp = Highlight.getRegexp(this.props.highlight);

        let listElements = [];

        this.props.items.forEach((item, index) => {

            let itemDataType = item.listType || this.props.dataType;

            let itemHelpers = getElementHelpers(
                        itemDataType,
                        this.props.displayFavorites ? this.props.favorites[itemDataType] : null,
                        this.props.pageKey,
                        this.props.displayFavorites);

            let key = item.id ? `${itemDataType}-${item.id}` : `${itemDataType}-${index}`;


            if (item) {
                listElements.push(
                    <ListElement
                        isNoteItem={this.props.isNotesList}
                        notes={this.props.notes}
                        note={this.props.isNotesList ? Notes.getNote(item.id,itemDataType) : null}
                        isFastAndUgly={this.props.isFastAndUgly}
                        hasFavoriteButton={this.props.displayFavorites}
                        showGoToButton={this.props.showGoToButton}
                        goToButtonLabel={this.props.showGoToButton ? this.props.labels.map.goTo : ''}
                        contactedOnLabel={this.props.labels.common.contactedOn}
                        onClick={this.handleClickOnLi}
                        {...this.getRowProps(item, itemHelpers, highlightRegexp)}
                        key={key} />
                );
            }
        });

        return listElements;
    }

    render() {


        if (!this.props.dataType) {
            return null;
        }
        // Nothing found
        if ((!this.props.items || !this.props.items.length) && this.props.isPending !== true) {
            return <NoResult labels={this.props.labels} />;
        }

        const ulClassNames = [ 'list-component', this.props.dataType ];
        if (this.props.isFastAndUgly) {
            ulClassNames.push('list-is-fast-and-ugly');
        }
        if (this.props.className) {
            ulClassNames.push(this.props.className);
        }

        return (
            <ul className={ulClassNames.join(' ')}
                style={{ height: this.state.height }}
                data-search-type={this.props.searchType}
                onClick={this.handleClickOnUl}>
                { this.getElements() }
            </ul>
        );
    }

    /**
     * Handle click on list (row, fav icon, ...)
     * @param {object} e: event
     */

    handleClickOnLi = e => {
        let target = e.target;

        let liEl;
        if (target.tagName === 'LI') {
            liEl = target;
        } else {
            liEl = findParentNode(target, el => el.tagName === 'LI');
        }

        if (!liEl) {
            // User clicked on the <ul> but not on a <li>, e.g bottom of the list. simply ignore it.
            // console.warn(LOG_PREF+'Could not find <li> related to event target:', target);
            return;
        }

        // No action when separator is clicked
        if (liEl.classList.contains('horizontal-separator')) {
            return;
        }

        // Click on favorite icon
        if (target.classList.contains('star')) {
            const { id, type } = liEl.dataset,
                isFav = target.classList.contains('is-fav');

            this.props.actions.toggleFavorite(id, type, isFav);


        // Click on "go to" button
        } else if (target.classList.contains('go-to')) {
            let { type, originalId } = liEl.dataset;
            type = convertDataTypeToMobigeoType(type);
            this.props.actions.dispatchItinerary(
                { placeId: this.props.contextualPlaceId,
                  type   : type,
                  originalId     : originalId,
                });

            // Close dialog
            if (typeof this.props.onElementSelected === 'function') {
                this.props.onElementSelected();
            }


        } else {
            // Click on item
            if (liEl && typeof liEl.dataset.isClickable !== 'undefined') {

                const initialBackgroundColor = liEl.style.backgroundColor;
                liEl.style.backgroundColor = ROW_BACKGROUND_COLOR_ON_CLICK;
                window.setTimeout(() => {
                    liEl.style.backgroundColor = initialBackgroundColor;

                    // Close dialog
                    if (typeof this.props.onElementSelected === 'function') {
                        this.props.onElementSelected();
                    }

                    // Execute optionnal func when click on li
                    if (this.props.onItemClick && typeof this.props.onItemClick === 'function') {
                        this.props.onItemClick()
                    }

                    // Execute configured action
                    if(this.props.isNotesList){
                        onClickOnNote(liEl, this.props.actions, this.props.labels, this.props.pageKey);
                    }
                    else
                        onClickOnLi(liEl, this.props.actions, this.props.labels, this.props.pageKey);


                }, config.DELAY_BEFORE_CLICK_ACTION);
            }
        }
    }

};

List.propTypes = {
    notes           : PropTypes.object,
    items           : PropTypes.array,
    isPending       : PropTypes.bool,
    dataType        : PropTypes.oneOf(VALID_DATA_TYPES).isRequired,
    displayFavorites: PropTypes.bool,
    favorites       : PropTypes.object,
    isFastAndUgly   : PropTypes.bool,
    showGoToButton  : PropTypes.bool,
    isPMREnabled    : PropTypes.bool.isRequired,

    // i18n labels (set for current language)
    labels: PropTypes.object.isRequired,

    // if specified, any occurence of this string will be emphasised (useful when displaying of list of search results)
    highlight: PropTypes.string,

    clickOnTypeBar: PropTypes.func,

    // Optional function to execute when an element has been selected
    onElementSelected: PropTypes.func,

    // Optional argument for function `onClickOnLi`
    pageKey: PropTypes.string,

    // Optional context, e.g data list dialog on map
    contextualPlaceId: PropTypes.number,

    // Optional context for search results
    searchType: PropTypes.string,

    // optional action on for click on li
    onItemClick: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
    return {isPMREnabled: state[MAP_PAGE_KEY].isPMREnabled}
}
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(List);
