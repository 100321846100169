
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { GestureDetector }    from 'onsenui';
import { debounce }           from 'lodash';
import Modal from 'react-modal';

import { MOBIGEO_LOG_LEVEL } from 'data/config/mapConfig';
import { MAP_PAGE_KEY } from 'src/pages/pagesKeys';

import Menu from 'src/components/menu/Menu';
import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import MobilespotButton from 'src/components/mobilespot-button/MobilespotButton';

import MobiGeoAssetsProvider from './MobiGeoAssetsProvider';
import { shouldAutoStart } from './locationHelper';
import { init as initFeatureStatus } from 'src/core/mapFeatures';

import { StoredManualPMRStatus } from 'src/pages/pmr/PmrHelper';

import MapContext   from './MapContext';
import * as actions from 'src/store/actions';

import './MapPage.scss';


const LOG_PREF = '[MapPage] ';


const getMobiGeoLoadOptions = props => ({
    lang: props.labels.id,
});

const getMobiGeoMapCreateOptions = () => ({
    showWatermark: false,
});


const MENU_OPTIONS = {
    swipeTargetWidth: '30%',
};

const customStyles = {
  content : {
    top        : '50%',
    left       : '50%',
    right      : 'auto',
    bottom     : 'auto',
    marginRight: '-50%',
    transform  : 'translate(-50%, -50%)',
    padding    : 0
  },
  overlay:{
    zIndex     : 50000
  }
};
let rootEl = document.getElementById('app-root');
Modal.setAppElement(rootEl)

class MapPage extends Component {

    constructor(props) {
        super(props);

        this.pageKey = MAP_PAGE_KEY;

        this.state = {
          modalIsOpen: false
        };

        this.openPMRModal = this.openPMRModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        // @see ../../README.md#using-global-variables
        this.MobiGeo = window.MobiGeo;
        if (this.MobiGeo) {
            this.MobiGeo.setLogLevel(MOBIGEO_LOG_LEVEL);
            this.bindEventHandlers();
        }
        this.containerId  = 'map-container';
    }

    openPMRModal() {
        this.setState({modalIsOpen: true});
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    closeModal() {
        this.setState({modalIsOpen: false});
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.getPageTitle());
        }
    }

    /**
     * String displayed in app toolbar
     * @return {string}
     */
    getPageTitle() {
        return this.props.labels.map.title;
    }

    /**
     * Listen to MobiGeo events
     */
    bindEventHandlers() {
        // Map loaded
        this.MobiGeo.Map.on('ready', () => {
            MapContext.setStatusLoaded();

            if (this.props.shouldRestart){
                this.restart();
            } else {
                console.log(LOG_PREF+'Map has successfully been loaded');

                // Refresh features availability
                initFeatureStatus();

                // Update state (e.g shouldRestart=false)
                this.props.actions.mapLoaded();
            }
        });
    }

    restart() {
        this.props.actions.mapIsRestarting();
        MapContext.setStatusRestarting();
        console.log(LOG_PREF+'Map restart');
        this.loadDataset();
    }

    _loadDataset() {
        if (this.props.isDataReady !== true) {
            console.log(LOG_PREF+'Skipping map loading because data is not available yet');
            return;
        }

        if (!MapContext.isLoading()) {
            console.info(LOG_PREF+'Load map');
            MapContext.setStatusLoading();
            this.MobiGeo.Location.autoStart = shouldAutoStart();
            this.MobiGeo.Map.POI.disablePopups = true;
            this.MobiGeo.setProviders(MobiGeoAssetsProvider);

            this.MobiGeo.load(this.props.mobigeoApiKey, getMobiGeoLoadOptions(this.props), err => {
                if (err) {
                    console.error(LOG_PREF+'MobiGeo failed to unlock data set because: '+err);
                    MapContext.setStatusNotLoaded();
                    return;
                }
                this.MobiGeo.Map.create(document.getElementById(this.containerId), getMobiGeoMapCreateOptions(), err => {
                    if (err) {
                        console.warn(LOG_PREF+'MobiGeo.Map.create thrown error '+err);
                        this.props.actions.mobigeoErrorThrown(err, 'MobiGeo.Map.create');

                        if (err === 'MAP_ERROR_INVALID_PARENT_SIZE') {
                            const delay = 1;
                            console.info(LOG_PREF+'Map container is too small, retry in '+delay+' second(s).');
                            window.setTimeout(this.loadDataset, 1*1000);
                        }
                        MapContext.setStatusNotLoaded();
                    }
                    // in case of success, see 'ready' event handler (see bindEventHandlers)
                });
            });
        }
    }

    loadDataset = debounce(this._loadDataset.bind(this), 150)

    componentWillMount() {
        this.toggleLoaderAccordingToDataReadyStatus(this.props);
    }

    componentWillReceiveProps(nextProps) {
        this.toggleLoaderAccordingToDataReadyStatus(nextProps);
    }

    toggleLoaderAccordingToDataReadyStatus(props) {
        if (props.isDataReady !== true) {
            this.props.actions.showFullLoader();
        } else {
            this.props.actions.hideFullLoader();
        }
    }

    /**
     * Note: returning true does not result in MobiGeo loading again from scratch.
     */
    shouldComponentUpdate(nextProps) {
        if (nextProps.isDataReady !== true) {
            return false;
        }

        // Window has been resize
        if (this.props.lastResize !== nextProps.lastResize) {
            if (MapContext.isNotLoaded()) {
                this.loadDataset();
            } else {
                this.MobiGeo.Map.resize();
            }

            if (this.props.orientation !== nextProps.orientation) {
                return true
            }

            return false;
        }

        // Data or assets have been updated
        /*if (this.props.shouldRestart !== nextProps.shouldRestart) {
            if (nextProps.shouldRestart === true) {
                MapContext.setStatusNotLoaded(); // => MobiGeo reload
            }
        }*/

        return true;
    }

    /**
     * Load MobiGeo dataset if data was not ready when the component has been mounted.
     * (e.g direct access to the page via url)
     */
    componentDidUpdate() {
        if (this.props.shouldRestart) {
            this.restart();
        }
        this.setDocumentContext();
    }

    /**
     * Load MobiGeo dataset if data is ready when the component is mounted.
     * (e.g coming from another page)
     */
    componentDidMount() {
        this.loadDataset();
        this.setDocumentContext();
    }

    mapContainerElementDidRender = el => {
        console.log(LOG_PREF+'dispose gestureDetector on: ', el);
        // Avoid Onsen's hammerjs messing with MobiGeo
        // @see https://onsen.io/v2/docs/guide/js/#adding-page-content  § Gesture detector
        if (el) {
            GestureDetector(el).dispose();
        }
    }
    setPMR(value){
        if (value === true) {
            this.props.actions.togglePMRStatus(true)
            StoredManualPMRStatus.setEnabled()
            this.props.actions.hidePMRModal()
        } else {
            this.props.actions.togglePMRStatus(false)
            StoredManualPMRStatus.setDisabled()
            this.props.actions.hidePMRModal()
        }
        this.props.actions.dispatchItinerary(this.props.routingDestinationPoi);
    }
    render() {
        console.log(LOG_PREF+'render');

        // Always keep that page's DOM
        // if (!this.props.isVisible) return null;

        if (this.props.isDataReady !== true) {
            return null;
        }
        else {
            return (
                <Menu
                    actions={this.props.actions}
                    labels={this.props.labels}
                    profile={this.props.profile}
                    options={Object.assign({}, MENU_OPTIONS, {isOpen: this.props.isMenuOpen})}
                    associatedPageKey={this.pageKey}
                    adConfig={this.props.adConfig}
                    twoColumns={this.props.twocolumns}
                    isLocationEnabled={this.props.isLocationEnabled}
                    >
                         <Modal
                          isOpen={this.props.isPMRModalOpen}
                          onAfterOpen={this.afterOpenModal}
                          onRequestClose={this.closeModal}
                          style={customStyles}
                          contentLabel="Example Modal"
                        >
                        <div>
                        <div class="alert-dialog-container">
                        <div class="list-dialog-container">

                        <div class="ps-row">
                        <div class="ps-bar modal horizontal-separator">
                        {this.props.labels.menu.pmrQuestion}
                        </div>
                        </div>
                        <div class="sp-buttons-container modal">
                            <div
                            className="btn-choose-dest" onClick={()=>this.setPMR(true)}>{this.props.labels.common.yes}</div>
                                <div
                            className="btn-choose-dest" onClick={()=>this.setPMR(false)}>{this.props.labels.common.no}</div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </Modal>
                    <AppToolbar
                        labels={this.props.labels}
                        isDisplayed={this.props.hasToolbar}
                        actions={this.props.actions}
                        title={this.getPageTitle()}
                        pageKey={this.pageKey}
                        profile={this.props.profile}
                        hasBackButton={this.props.backButtonInToolbar}
                        hasHomeButton={this.props.homeButtonInToolbar}
                        hasFavButton={this.props.favButtonInToolbar}
                        hasSearchButton={this.props.searchButtonInToolbar}
                        hasMenuButton={this.props.menuButtonInToolbar}>

                        <div id="map-container-wrapper"> {/* TODO  style={{ height: this.props.height }} */}

                            {/* MobiGeo container */}
                            <div id={this.containerId} ref={this.mapContainerElementDidRender}></div>

                            { this.props.hasMobileSpotButton &&
                                <MobilespotButton labels={this.props.labels}/>
                            }
                        </div>

                    </AppToolbar>
                </Menu>
            );
        }
    }
}

MapPage.propTypes = {
    lastResize   : PropTypes.number,
    isDataReady  : PropTypes.bool,
    shouldRestart: PropTypes.bool,
    mobigeoApiKey: PropTypes.string.isRequired,
    hasMobileSpotButton: PropTypes.bool,
    // Common page props
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled : PropTypes.bool,
    isPMREnabled:       PropTypes.bool,
    // toolbar
    hasToolbar           : PropTypes.bool,
    homeButtonInToolbar  : PropTypes.bool,
    backButtonInToolbar  : PropTypes.bool,
    searchButtonInToolbar: PropTypes.bool,
    favButtonInToolbar   : PropTypes.bool,
    menuButtonInToolbar  : PropTypes.bool,
    routingDestinationPoi: PropTypes.object,
    isPMRModalOpen : PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
    return state[MAP_PAGE_KEY]

};
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapPage);
