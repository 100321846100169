
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AdBanner from 'src/components/ad-banner/AdBanner';
import { getLeastSeenAdFromBundle } from 'src/core/config-json/ConfigJsonManager';

import * as actions from 'src/store/actions';


export const STORE_KEY = 'AdSwap';


class AdSwap extends React.Component {

    getBundle() {
        // If any property is missing, skip component rendering
        if (this.props.adKey
                && this.props.adConfig
                && this.props.adConfig.ads
                && this.props.adConfig.swap_delay
                && this.props.adConfig.bundles
                && this.props.adConfig.bundleAttributions) {

            let bundleName = this.props.adConfig.bundleAttributions[this.props.adKey];
            if (!bundleName) {
                console.log('No ad bundle for '+this.props.adKey);
                return null;
            }
            return this.props.adConfig.bundles[bundleName];
        }
    }

    triggerRender = (() => {
        if (typeof this.props.isPageVisible === 'boolean' && this.props.isPageVisible) {
            this.setState({ time: new Date().getTime() });
        }
    }).bind(this)

    componentWillUnmount() {
        this.unmounted = true;
    }

    /*shouldComponentUpdate(nextProps) {
        if (this.unmounted) {
            return false;
        }
        return nextProps !== this.props;
    }*/

    render() {
        let bundle = this.getBundle();
        if (!bundle) {
            return null;
        }

        let leastSeenAd = getLeastSeenAdFromBundle(bundle, this.props.adConfig.ads)
        if (!leastSeenAd) {
            return null;
        }

        let adFiles = this.props.adConfig.adsFiles[leastSeenAd.name];
        if (!adFiles) {
            return null;
        }

        window.setTimeout(this.triggerRender, this.props.adConfig.swap_delay);

        return (
            <AdBanner
                ad={leastSeenAd}
                adFiles={adFiles}
                currentLang={this.props.labels.id}
                actions={this.props.actions} />
        );
    }
}

AdSwap.propTypes = {
    adKey: PropTypes.string, // can be null if no ad is specified for a list
    isPageVisible: PropTypes.bool,
    // from store:
    adConfig: PropTypes.object,
    actions : PropTypes.object,
    labels  : PropTypes.object,
};

const mapStateToProps = (state, ownProps) => state[STORE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AdSwap);
