import { iosAppStoreUrl, playStoreUrl, name, author } from "data/config/config";
import { isIOS, isAndroid } from "src/core/util/browser";
import { getCurrent } from "src/core/Lang";

export function injectSmartBanners() {
	const hasSmartBanner =
		!global.isCordovaContext && (isIOS() ? iosAppStoreUrl : playStoreUrl);

	/*
	// smartbanner
        // see: https://github.com/ain/smartbanner.js
        SMARTBANNER_META_TAGS: !hasSmartBanner ? '' : [
                '<meta name="smartbanner:title" content="'+projectConfig.native.name.default+'">',
                '<meta name="smartbanner:author" content="'+projectConfig.native.author+'">',
                '<meta name="smartbanner:price" content=" ">',
                '<meta name="smartbanner:price-suffix-apple" content=" ">',
                '<meta name="smartbanner:price-suffix-google" content=" ">',
                '<meta name="smartbanner:icon-apple" content="./lib/img/apple-app-store.png">',
                '<meta name="smartbanner:icon-google" content="./lib/img/google-play.png">',
                '<meta name="smartbanner:button" content="VIEW">',
                '<meta name="smartbanner:button-url-apple" content="'+projectConfig.native.iosAppStoreUrl+'">',
                '<meta name="smartbanner:button-url-google" content="'+projectConfig.native.playStoreUrl+'">',
                '<meta name="smartbanner:enabled-platforms" content="android,ios">',
            ].join(''),
        SMARTBANNER_LINK_TAG: !hasSmartBanner ? '' : '<link rel="stylesheet" href="./lib/smartbanner.min.css">',
        SMARTBANNER_SCRIPT_TAG: !hasSmartBanner ? '' : '<script src="./lib/smartbanner.min.js"></script>',
	*/

	if (hasSmartBanner) {
		var metaTitle = document.createElement("meta");
		metaTitle.name = "smartbanner:title";
		metaTitle.setAttribute("content", name);
		document.getElementsByTagName("head")[0].appendChild(metaTitle);

		var metaAuthor = document.createElement("meta");
		metaAuthor.name = "smartbanner:author";
		metaAuthor.setAttribute("content", author);
		document.getElementsByTagName("head")[0].appendChild(metaAuthor);

		var metaPrice = document.createElement("meta");
		metaPrice.name = "smartbanner:price";
		metaPrice.setAttribute("content", " ");
		document.getElementsByTagName("head")[0].appendChild(metaPrice);

		if (isIOS()) {
			var metaPriceSuffix = document.createElement("meta");
			metaPriceSuffix.name = "smartbanner:price-suffix-apple";
			metaPriceSuffix.setAttribute("content", " ");
			document
				.getElementsByTagName("head")[0]
				.appendChild(metaPriceSuffix);

			var metaIcon = document.createElement("meta");
			metaIcon.name = "smartbanner:icon-apple";
			metaIcon.setAttribute("content", "./lib/img/apple-app-store.png");
			document.getElementsByTagName("head")[0].appendChild(metaIcon);

			var metaButtonUrl = document.createElement("meta");
			metaButtonUrl.name = "smartbanner:button-url-apple";
			metaButtonUrl.setAttribute("content", iosAppStoreUrl);
			document.getElementsByTagName("head")[0].appendChild(metaButtonUrl);

			var metaEnabledPlatforms = document.createElement("meta");
			metaEnabledPlatforms.name = "smartbanner:enabled-platforms";
			metaEnabledPlatforms.setAttribute("content", "ios");
			document
				.getElementsByTagName("head")[0]
				.appendChild(metaEnabledPlatforms);
		} else {
			var metaPriceSuffix = document.createElement("meta");
			metaPriceSuffix.name = "smartbanner:price-suffix-google";
			metaPriceSuffix.setAttribute("content", " ");
			document
				.getElementsByTagName("head")[0]
				.appendChild(metaPriceSuffix);

			var metaIcon = document.createElement("meta");
			metaIcon.name = "smartbanner:icon-google";
			metaIcon.setAttribute("content", "./lib/img/google-play.png");
			document.getElementsByTagName("head")[0].appendChild(metaIcon);

			var metaButtonUrl = document.createElement("meta");
			metaButtonUrl.name = "smartbanner:button-url-google";
			metaButtonUrl.setAttribute("content", playStoreUrl);
			document.getElementsByTagName("head")[0].appendChild(metaButtonUrl);

			var metaEnabledPlatforms = document.createElement("meta");
			metaEnabledPlatforms.name = "smartbanner:enabled-platforms";
			metaEnabledPlatforms.setAttribute("content", "android");
			document
				.getElementsByTagName("head")[0]
				.appendChild(metaEnabledPlatforms);
		}

		var metaButton = document.createElement("meta");
		metaButton.name = "smartbanner:button";
		metaButton.setAttribute(
			"content",
			getCurrent() == "fr" ? "VOIR" : "VIEW"
		);
		document.getElementsByTagName("head")[0].appendChild(metaButton);

		var linkElement = document.createElement("link");
		linkElement.setAttribute("rel", "stylesheet");
		linkElement.setAttribute("href", "./lib/smartbanner.min.css");

		document.getElementsByTagName("head")[0].appendChild(linkElement);

		var script = document.createElement("script");
		script.setAttribute("src", "./lib/smartbanner.min.js");
		script.setAttribute("type", "text/javascript");
		document.getElementsByTagName("body")[0].appendChild(script);
	}
}
