
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    DATA_TYPE_EVENTS,
    DATA_TYPE_PARTICIPANTS,
} from 'data/config/dataConfig';

// import { PARTICIPANT_PAGE_KEY } from 'src/pages/pagesKeys';

// import DetailCountry      from 'src/components/detail-country/DetailCountry';
import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle   from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailSocial       from 'src/components/detail-social/DetailSocial';
import UseRemainingHeight from 'src/components/use-remaining-height/UseRemainingHeight';
import Description        from 'src/components/fiche/Description';
// import PhoneRow           from 'src/components/fiche/PhoneRow';
// import EmailRow           from 'src/components/fiche/EmailRow';
import WebsiteRow         from 'src/components/fiche/WebsiteRow';
// import ShareButton        from 'src/components/share-button/ShareButton';
// import NoteButton         from 'src/components/notes/NoteButton';
import ContactButton from 'src/pages/participant/ContactButton';


class ParticipantContent extends PureComponent {

    setScroller = ref => {
        // Used by parent component
        this.scroller = ref;
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_PARTICIPANTS,
            this.props.isFavorite
        );
    }

    showEvents = () => {
        this.props.actions.navigateToItems(
            this.props.item.references[DATA_TYPE_EVENTS],
            DATA_TYPE_EVENTS,
            this.props.item.id,
            DATA_TYPE_PARTICIPANTS);
    }

    getTitle = item => (
          (item.civility  ? item.civility+' ' : '')
        + (item.firstName ? item.firstName+' ' : '')
        + (item.lastName  ? item.lastName : '')
    )

    render() {
        /* {
          "id": "7a8f1c7f-6fea-e911-80e2-005056ae0696",
          "type": "",
        x "civility": "",
        x "firstName": "Stéphane",
        x "lastName": "DAMAY",
        x "email": "sdamay@leni.fr",
        x "profilePicture": [],
        x "phoneNumber": "0320000000",
        x "linkedIn": "",
          "role": "Dev",
        x "visible": true,
        x "contactable": true,
          "organizationName": "LENI",
          "organizationLogo": [],
          "organisationWebsite": "http://www.leni2.fr",
          "contactedParticipants": [],
          "events": [],
          "externalRessources": []
        }*/

        const
            title = this.getTitle(this.props.item),
            logoUrl = Array.isArray(this.props.item.profilePicture) ? this.props.item.profilePicture[0] : this.props.item.profilePicture,
            isSameUser = this.props.item.references.isSameUser,
            contactDate = this.props.item.references.contactDate,
            contactDateLabel = this.props.item.references.contactDateLabel;

        return (
            <div className="fiche participant content-font" style={{ height: this.props.height }}>

                {/* title */}
                <DetailFixedTitle
                    title={title}
                    isFav={this.props.isFavorite}
                    onFavClick={this.onClickOnFavIcon}
                    labels={this.props.labels} >

                    {/* <NoteButton
                          itemTitle={title}
                          itemId={this.props.item.id}
                          dataType={DATA_TYPE_PARTICIPANTS}
                          hasNote={this.props.hasNote}
                          labels={this.props.labels}
                          actions={this.props.actions} /> */}

                    {/* <ShareButton
                        name={this.props.getPageTitle(this.props.item, this.props.labels)}
                        queryString={this.props.queryString}
                        description={description}
                        image={logoUrl}
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        pageKey={PARTICIPANT_PAGE_KEY} /> */}

                </DetailFixedTitle>

                <UseRemainingHeight ref={this.setScroller}>
                    <div className="content">
                        <div className="all-informations content-font">

                            <Description
                                logoFileName={logoUrl}
                                description={''}
                                isLogoFullWidth={this.props.isLogoFullWidth} />

                            {/* events }
                            <DetailEvents
                                items={this.props.item.references.events}
                                labels={this.props.labels}
                                onClick={this.showEvents} />
                            */}

                            {/* contacts bar */}
                            { (this.props.item.website || this.props.item.email || this.props.item.phone) &&
                                <div className="fiche-separator">{this.props.labels.common.information}</div>
                            }

                            {/* <DetailCountry country={this.props.item.country} /> */}

                            <DetailSocial data={{ ln: this.props.item.linkedIn }} actions={this.props.actions}/>

                            {/* <PhoneRow phone={this.props.item.phoneNumber} /> */}

                            { this.props.item.organisationWebsite &&
                                 <WebsiteRow
                                    website={this.props.item.organisationWebsite}
                                    actions={this.props.actions} />
                            }

                            {/* <EmailRow email={this.props.item.email} /> */}

                            { !isSameUser && this.props.item.visible && this.props.item.contactable &&
                                (contactDate
                                    ? <span>{this.props.labels.common.contactedOn} {contactDateLabel}</span>
                                    : <div className="contact-button-container"><ContactButton participantId={this.props.item.id} /></div>
                            )}

                        </div>
                    </div>
                </UseRemainingHeight>
            </div>
        );
    }
}

ParticipantContent.propTypes = {
    item        : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isFavorite  : PropTypes.bool,
    isLogoFullWidth: PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    getPageTitle: PropTypes.func.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
    height      : PropTypes.number, // prop set from AppToolbar
};

export default ParticipantContent;
