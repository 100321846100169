import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { ListItem } from 'react-onsenui';

import { LOCATION_BUTTON_PROPS } from 'data/config/menuConfig';
import { MAP_PAGE_KEY } from 'src/pages/pagesKeys';
import { StoredManualLocationStatus } from 'src/pages/map/locationHelper';
import * as actions from 'src/store/actions';



class ToggleLocationItem extends React.PureComponent {


    constructor(props) {

        super(props);

        this.isDisclaimerDisplayed = false;
    }
    toggleLocationCapability = event => {
        if (event && event.preventDefault)
            event.preventDefault();
        if (this.props.isLocationReady === true) {
            this.props.actions.stopLocation();

        } else {
            StoredManualLocationStatus.setEnabled();
            this.props.actions.requestLocationCapabilities();
        }
    }
    render() {
        if (!global.isCordovaContext) {
            return null;
        }


        // CONTAINER
        let entry = this.props.entry
        let hasContainerStyle = entry.style && typeof entry.style.container === 'object' && entry.style.container !== null,
            contAttrs = {},
            contClasses = [];

        // css
        if (hasContainerStyle && entry.style.container.style) {
            contAttrs.style = entry.style.container.style;
        }
        //if (isDisabled === true) {
        //    contClasses.push('menu-item-disabled');
        //}
        if (hasContainerStyle && entry.style.container.className) {
            contClasses.push(entry.style.container.className);
        }
        contAttrs.className = contClasses.join(' ');
        // ICON
        let hasIconStyle = entry.style && typeof entry.style.icon === 'object' && entry.style.icon !== null,
            iconClasses = ['menu-icon'];

        if (hasIconStyle && entry.style.icon.className) {
            iconClasses.push(entry.style.icon.className);
        }

        return (
            <ListItem className="list-item" tappable={true} onClick={this.toggleLocationCapability}>
                <div className="menu-item content-font">

                    {/* see:
                         https://github.com/markusenglund/react-switch
                         https://react-switch.netlify.com/
                     */}
                    <span className={ iconClasses.join(' ') }
                      style={ hasIconStyle && entry.style.icon.style ? entry.style.icon.style : null }
                      >{ hasIconStyle && typeof entry.style.icon.webfont === 'string' ? entry.style.icon.webfont : null }</span>


                    <div className="menu-text">
                        {this.props.label ? this.props.label : entry.label}
                    </div>

                </div>
                <div className="menu-item content-font switch" style={{pointerEvents: "none"}}>
                    <Switch
                        onChange={function(){}}
                        checked={this.props.isLocationReady}
                        className="react-switch"
                        {...LOCATION_BUTTON_PROPS} />
                </div>
            </ListItem>
        );
    }
};

ToggleLocationItem.propTypes = {
    actions: PropTypes.object.isRequired,
    isLocationReady: PropTypes.bool,
};

ToggleLocationItem.defaultProps = {
  isLocationReady: false,
}


const mapStateToProps = (state, ownProps) => state[MAP_PAGE_KEY]
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ToggleLocationItem);