
import ons from 'onsenui';



const LOG_PREF = '[FetchHelper] ';

const DEFAULT_FETCH_TIMEOUT = 15000;

let FETCH_ERRORS = {
    REQUEST: 'Request failed',
    PARSE  : 'Failed to parse json',
};


export const HEADERS = {
    JSON_CONTENT_TYPE: { name: 'Content-Type', value: 'application/json' },
};


/**
 * Common error handler
 * @param {string}   msg
 * @param {function} cb
 * @param {boolean} showModalOnError (optional)
 * @param {...[*]}   args
 */
function errorHandler(msg, cb, showModalOnError, ...args) {
    console.error(LOG_PREF+msg, args);

    // UI display error
    if (showModalOnError !== false) {
        ons.notification.alert({
            title: 'Error',
            message: msg ? msg : 'Failed to retrieve data',
        });
    }

    if (typeof cb === 'function') {
        cb(msg);
    }
};

/**
 * Handle non-JSON requests
 * @param {object} promise
 * @param {string} url (used for error logging only)
 * @param {function} successCb (optional)
 * @param {function} errorCb   (optional)
 * @param {boolean} showModalOnError (optional)
 */
function commonHandler(promise, url, successCb, errorCb, showModalOnError) {
    promise.then(
        (response) => {
            if (response.status >= 400) {
                errorHandler(FETCH_ERRORS.REQUEST + ` (${response.status})`, errorCb, showModalOnError, url);
            } else {
                if (typeof successCb === 'function') {
                    // Allows any exception raised in callback to not be caught by promise
                    window.setTimeout(successCb, 1, response);
                }
            }
        },
        // fetch failure
        (...args) => {
            errorHandler(FETCH_ERRORS.REQUEST, errorCb, showModalOnError, url, ...args);
        });
};

/**
 * Handle JSON requests
 * @param {object} promise
 * @param {string} url (used for error logging only)
 * @param {function} successCb (optional)
 * @param {function} errorCb   (optional)
 * @param {boolean} showModalOnError (optional)
 */
function jsonHandler(promise, url, successCb, errorCb, showModalOnError) {
    promise
        .then(
            response => {
                if (response.status >= 400) {
                    throw FETCH_ERRORS.REQUEST;
                } else {
                    return response.json();
                }
            },
            // fetch failure
            (...args) => {
                throw FETCH_ERRORS.REQUEST;
            })
        .then(
            // parse success
            json => {
                if (json) {
                    if (typeof successCb === 'function') {
                        // Allows any exception raised in callback to not be caught by promise
                        window.setTimeout(successCb, 1, json);
                    }
                } else {
                    throw FETCH_ERRORS.PARSE;
                }
            },
            // parse failure
            (...args) => {
                throw FETCH_ERRORS.PARSE;
            })
        .catch(function(reason) {
            errorHandler(reason, errorCb, showModalOnError, url);
        });
};

/**
 * Handle response for relative path in cordova app, and file:// protocol
 * @param  {string} url
 * @param  {object} opt (optional), currently handled options are: method, body, timeout
 */
function fetchUsingXhr(url, opt) {
    return new Promise(function(resolve, reject) {

        let hasOptions = typeof opt === 'object' && opt !== null;

        var xhr = new XMLHttpRequest();
        xhr.onload = function() {
            // On Safari status 0 (undocumented HTTP code) is returned when everything is fine
            resolve(new Response(xhr.responseText, { status: xhr.status || 200 }))
        };
        xhr.onerror = function(e) {
            reject(new TypeError('XHR request failed'), e)
        };

        const method = (hasOptions && opt.method) || 'GET';
        xhr.open(method, url);

        // Beware
        // On IE 11, options should be set after the call to 'open()', else an 'InvalidStateError' is thrown
        xhr.timeout = hasOptions && typeof opt.timeout === 'number'
                        ? opt.timeout
                        : DEFAULT_FETCH_TIMEOUT;

        // Set options
        if (hasOptions) {

            if (opt.headers != null) {
                opt.headers.forEach(header => {
                    xhr.setRequestHeader(header.name, header.value)
                });
            }
            if (opt.withCredentials) {
                xhr.withCredentials = true;
            }
        }

        xhr.send(opt && opt.body ? opt.body : null);
    })
}


/**
 * @param {string} url
 * @param {object} opt (optional) - request options (e.g method, timeout, headers, body)
 * @param {boolean} isJson (optional)
 * @param {function} successCb (optional)
 * @param {function} errorCb   (optional)
 * @param {boolean} showModalOnError (optional) - default: true
 */
export default function fetchHelper(url, opt, isJson, successCb, errorCb, showModalOnError) {
    let promise;

    console.debug(LOG_PREF+'Fetch \''+url+'\' with options:', opt);

    // Check url (not empty, type string)
    if (!url || typeof url !== 'string') {
        console.error(LOG_PREF+'Invalid \'url\' parameter', url);
        if (typeof errorCb === 'function') {
            errorCb();
        }
        return;
    }

    // Fetch polyfill does not handle file:// protocol
    if (true) {
    //if ((global.isCordovaContext && url.startsWith('http') === false) || url.slice(0, 7) === 'file://' || isIOS()) {
        promise = fetchUsingXhr(url, opt);

    } else {
        // Default (web-service, rest api, etc) is supposed to be json
        promise = fetch(url, opt);
    }

    if (isJson) {
        jsonHandler(promise, url, successCb, errorCb, showModalOnError);
    } else {
        commonHandler(promise, url, successCb, errorCb, showModalOnError);
    }
};
