
// Libs
import { createStore, applyMiddleware, bindActionCreators } from 'redux';
import createLogger    from 'redux-logger';
import thunkMiddleware from 'redux-thunk';

import * as _actions from './actions';

// Config
import config from 'data/config/config';

// Middlewares
import configJsonMiddleware   from 'src/core/config-json/configJsonMiddleware';
import pollManagerMiddleware  from 'src/core/polls/pollManagerMiddleware';
import contributionsFeedManagerMiddleware from 'src/core/contributions-feed/contributionsFeedManagerMiddleware';
import analyticsMiddleware    from 'src/core/analytics/analyticsMiddleware';
import bootMiddleware         from 'src/core/bootMiddleware';
import contextualSearchMiddleware from 'src/components/contextual-search/contextualSearchMiddleware';
import dataAssetsMiddleware   from 'src/core/data-and-assets/dataAssetsMiddleware';
import fullLoaderMiddleware   from 'src/components-standalone/full-loader/fullLoaderMiddleware';
import inboxMiddleware        from 'src/pages/inbox/inboxMiddleware';
import intersticielMiddleware from 'src/components-standalone/intersticiel/intersticielMiddleware';
import mapMiddleware          from 'src/pages/map/mapMiddleware';
import searchPlaceDialogMiddleware from 'src/components-standalone/search-place-dialog/searchPlaceDialogMiddleware';
import routerMiddleware       from 'src/core/navigation/routerMiddleware';
import loginMiddleware        from 'src/core/login/LoginMiddleware';
import participantsMiddleware from 'src/core/participants/participantsMiddleware';
import socialMiddleware       from 'src/core/social/socialMiddleware';
import appointmentRequestMiddleware from 'src/core/appointment-request/appointmentRequestMiddleware';
import synchronizedFavoritesMiddleware from 'src/core/favorites/synchronizedFavoritesMiddleware';
import { middleware as flightScheduleMiddleware } from 'src/pages/flights_schedule/flightsReducer';
import { middleware as iosWhitePageHackMiddleware } from 'src/core/util/iosWhitePageHack';

// App modules
import * as PollManager              from 'src/core/polls/PollManager';
import * as ContributionsFeedManager from 'src/core/contributions-feed/ContributionsFeedManager';
import * as Cordova                   from 'src/core/cordova/cordovaReducerAndMiddleware';
import * as GoogleAnalyticsHelper     from 'src/core/analytics/GoogleAnalyticsHelper';
import * as SocialService             from 'src/core/social/SocialService';
import * as Notes                     from 'src/core/notes/Notes';
import * as SearchPlaceDialog         from 'src/components-standalone/search-place-dialog/SearchPlaceDialog';
import * as DataListDialog            from 'src/components-standalone/data-list-dialog/DataListDialog';
import * as Router                    from 'src/core/navigation/Router';

import { isIOS } from 'src/core/util/browser';
import { setBindedActions } from './bindedActions';

import rootReducer from './reducers';


/**
 * Give a `store` reference to not connected modules
 * @param  {object} store
 * @param  {object} actions (binded)
 */

function giveStoreReferenceToNonReactModules(store, actions) {

    // Provide store
    Router.setReduxStore(store);
    PollManager.setReduxStore(store);
    SocialService.setReduxStore(store);
    SearchPlaceDialog.setReduxStore(store);
    DataListDialog.setReduxStore(store);
    Notes.setReduxStore(store);
}


export default function() {

    // Declare store middlewares
    let middlewares = [ thunkMiddleware ];

    middlewares.push(routerMiddleware);
    middlewares.push(dataAssetsMiddleware);
    middlewares.push(intersticielMiddleware);
    middlewares.push(bootMiddleware);
    middlewares.push(contextualSearchMiddleware);
    middlewares.push(fullLoaderMiddleware);
    middlewares.push(configJsonMiddleware);
    middlewares.push(searchPlaceDialogMiddleware);
    middlewares.push(mapMiddleware);
    middlewares.push(inboxMiddleware);
    middlewares.push(Cordova.createMiddleware(global.isCordovaContext ? cordova : null));

    if (/*global.isCordovaContext && */isIOS()) {
        middlewares.push(iosWhitePageHackMiddleware);
    }
    if (config.SOCIAL && config.SOCIAL.FEATURE_ENABLED) {
        middlewares.push(socialMiddleware);
    }
    if (config.POLL && config.POLL.FEATURE_ENABLED) {
        middlewares.push(pollManagerMiddleware);
    }
    if (config.FLIGHTS_SCHEDULE && config.FLIGHTS_SCHEDULE.FEATURE_ENABLED) {
        middlewares.push(flightScheduleMiddleware);
    }
    if (config.LOGIN && config.LOGIN.FEATURE_ENABLED) {
        middlewares.push(loginMiddleware);
        middlewares.push(participantsMiddleware); // rely on login
    }
    if (config.APPOINTMENT_REQUEST && config.APPOINTMENT_REQUEST.FEATURE_ENABLED === true) {
        middlewares.push(appointmentRequestMiddleware);
    }
    if (config.SYNCHRONIZED_FAVORITES && config.SYNCHRONIZED_FAVORITES.FEATURE_ENABLED === true) {
        middlewares.push(synchronizedFavoritesMiddleware);
    }
    if (GoogleAnalyticsHelper.isEnabled()) {
        middlewares.push(analyticsMiddleware);
    }
    if (ContributionsFeedManager.isEnabled()) {
        middlewares.push(contributionsFeedManagerMiddleware);
    }

    // logger must be the last middleware in chain
    // source: https://github.com/LogRocket/redux-logger
    if (config.ENV === 'dev') {
        middlewares.push(createLogger);
    }

    // Create store
    let store = createStore(
        rootReducer,
        applyMiddleware( ...middlewares )
    );

    const actions = bindActionCreators(_actions, store.dispatch);
    setBindedActions(actions);

    giveStoreReferenceToNonReactModules(store, actions);

    console.info('Store initialized');

    return {
        store,
        actions,
    };
}
