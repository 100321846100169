import { SYNOPTIC_AGENDA_PAGE_KEY } from 'src/pages/pagesKeys.js';

import SynopticAgendaPage from 'src/pages/synoptic-agenda/SynopticAgendaPage';
import synopticAgendaPageReducer from 'src/pages/synoptic-agenda/synopticAgendaPageReducer';

export default {
    key      : SYNOPTIC_AGENDA_PAGE_KEY,
    path     : '/synoptic-agenda',
    component: SynopticAgendaPage,
    elId     : 'synoptic-agenda-page',
    getReduxReducer: (state, action) => synopticAgendaPageReducer(state[SYNOPTIC_AGENDA_PAGE_KEY], action),
};
