
import config from 'data/config/config';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { ERRORS } from 'src/core/login/LoginService';
import STATUSES from '../statuses';

const LOG_PREF = '[ParticipantsWS Taiga] ';


export function getParticipants(cb) {
    const url = config.PARTICIPANTS.WS_URL+'/tgServices/BlockchainAppWs/getParticipants.htm';

    let opts = {
        method: 'GET',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        withCredentials: true, // use cookie (the browser takes care of it)
    };
    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onSuccess(response) {
            switch(response.status) {
                case STATUSES.SUCCESS:

                    console.log('WITH EVENTS');
                    console.log(response.datas.filter(p => Array.isArray(p.events) && p.events.length > 0));

                    cb(
                        null, // error code
                        response.datas, // participants
                    );
                    break;

                case STATUSES.ERROR:
                    // FIXME undocumented
                    cb(ERRORS.UNKNOWN);
                    break;

                default: console.error(LOG_PREF+'Unexpected response status: '+response.status);
            }
        },
        function onFailure(error, message) {
            cb(ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}

export function contactParticipant(id, cb) {
    const url = config.PARTICIPANTS.WS_URL+'/tgServices/BlockchainAppWs/contactParticipant.htm';

    let opts = {
        method: 'POST',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        withCredentials: true, // use cookie (the browser takes care of it)
        body: JSON.stringify({ contactId: id }),
    };
    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onSuccess(response) {
            switch(response.status) {
                // {"status":"success","datas":{"message":"Contact added"}}
                case STATUSES.SUCCESS:
                    cb();
                    break;

                case STATUSES.ERROR:
                    // FIXME undocumented
                    cb(ERRORS.UNKNOWN);
                    break;

                default: console.error(LOG_PREF+'Unexpected response status: '+response.status);
            }
        },
        function onFailure(error, message) {
            cb(ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}