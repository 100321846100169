import _ from 'lodash';
import moment from 'moment';
import fetchHelper from 'src/core/util/FetchHelper';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';
import { pollConfigLoaded, showPollDialog } from 'src/store/actions';
import { prefixWith } from 'src/store/reducers/utils';
import { getParameterByName } from 'src/core/util/JsTools';

import {
	isSessionValid
} from 'src/core/login/LoginService'

import { COMPONENT_KEY } from 'src/components-standalone/poll-dialog/PollDialog'
import { ERRORS } from 'src/core/polls/PollService'

const LOG_PREF = '[PollManager]';
export const QUERY_PARAMETER_KEY = 'pollId';
export const POLL_CONFIG_PATH = '/files/project/config.json';
export const prefix = prefixWith('poll');

let reduxStore,
    pollConfig;

export const init = () => {

    const pollConfigUrl = getUrl(POLL_CONFIG_PATH);
    console.log(LOG_PREF+'About to load poll config from '+pollConfigUrl);

	// get parameter string to control the poll dialog window display with a query parameter
	// query value is supposed to be a pollId
	const pollId = getParameterByName(QUERY_PARAMETER_KEY);

    fetchHelper(pollConfigUrl, null, true,
        (data) => {
            console.log('Poll config loaded', data);
            pollConfig = data;
            reduxStore.dispatch(pollConfigLoaded(pollConfig));

			// if query param was present with a pollId
			if (pollId) {
				reduxStore.dispatch(showPollDialog(pollId));
			}
        },
        (err) => {
            console.warn('could not get poll config', err);
            // Dispatch action even if request has failed to allow boot sequence to start
            // @see store/reducers/bootMiddleware
            reduxStore.dispatch(pollConfigLoaded(pollConfig));
        },
        // Do not display a modal on error
        false
    );

};


export const getPollConfig = (profile) => pollConfig && pollConfig.polls ? pollConfig.polls : null;

export const setReduxStore = (store) => {
    reduxStore = store;
};

export const getPollList = (profile) => {
	const state = reduxStore && reduxStore.getState ? reduxStore.getState() : null

	if (state && state[COMPONENT_KEY] && state[COMPONENT_KEY].pollConfig) {
		const config = state[COMPONENT_KEY].pollConfig
		return config
			.filter(poll => !poll.poll_profiles || poll.poll_profiles.indexOf(profile) > -1)
			.map(poll => {
				const pollJson = window.localStorage.getItem(prefix(poll.poll_id))
				const closeDate = moment(poll.poll_close_date, 'YYYY/MM/DD HH:mm')
				const isClosed = moment().isAfter(closeDate)

				let localState = 'notStarted'

			    if (pollJson) {
			        const storedAnswers = JSON.parse(pollJson)
						if (poll) {
							if (storedAnswers.submitted) localState = 'submitted'
							else localState = 'started'
						}
			    }

				return {
					...poll,
					local_state: localState,
					opened: !isClosed
				}
			})
	}

	return null
}

export const getPollConfigById = (id) => {
	const state = reduxStore && reduxStore.getState ? reduxStore.getState() : null
	if (state && state[COMPONENT_KEY] && state[COMPONENT_KEY].pollConfig) {
		const config = state[COMPONENT_KEY].pollConfig
		return _.find(config, (poll) => `${poll.poll_id}` === `${id}`) || null
	}
	return null
}

export const getPollConfigByCode = (code) => {
	const state = reduxStore && reduxStore.getState ? reduxStore.getState() : null

	if (state && state[COMPONENT_KEY] && state[COMPONENT_KEY].pollConfig) {
		const config = state[COMPONENT_KEY].pollConfig
		return _.find(config, (poll) => poll.poll_code === code) || null
	}

	return null
}

export const getQuestionConfigById = (poll_id, question_id) => {
    const pollConfig = getPollConfigById(poll_id)
    return _.find(pollConfig.questions, (question) => question.q_id === question_id)
}

export const getQuestionConfigByRank = (poll_id, rank) => {
    const pollConfig = getPollConfigById(poll_id)
    return _.find(pollConfig.questions, (question) => question.q_order === rank)
}

export const getPollStep = (poll_id, question_id) => {
    const pollJson = window.localStorage.getItem(prefix(poll_id))
    const poll = pollJson ? JSON.parse(pollJson) : { answers: {}}
    const questionConfig = getQuestionConfigById(poll_id, question_id)
    const answer = poll.answers[question_id]
    let fields = {}
    if (answer) {
        const keys = Object.keys(answer)
        if (keys.indexOf('mark') > -1) {
            fields.mark = answer.mark
            fields.comment = ''
            fields.choice = ''
			fields.multiple = []
        }
        else if (keys.indexOf('choice') > -1) {
            fields.choice = answer.choice
            fields.mark = 0
            fields.comment = ''
			fields.multiple = []
        }
		else if (keys.indexOf('multiple') > -1) {
            fields.choice = ''
            fields.mark = 0
            fields.comment = ''
			fields.multiple = answer.multiple
        }
        else {
            fields.comment = answer.comment
            fields.mark = 0
            fields.choice = ''
			fields.multiple = []
        }
    }

    return {
        data: answer || null,
        config: questionConfig || null,
        ...fields
    }
}

export const setPollStep = (poll_id, question_id, answer) => {
    const pollJson = window.localStorage.getItem(prefix(poll_id))
    const poll = pollJson ? JSON.parse(pollJson) : { answers: {}, submitted: false }
    poll.answers[question_id] = answer
    window.localStorage.setItem(prefix(poll_id), JSON.stringify(poll))
}

export const getNextPollStep = (poll_id, current_question_id) => {
    const question = getQuestionConfigById(poll_id, current_question_id)
    const rank = question.q_order
    const nextRank = rank + 1
    const nextQuestion = getQuestionConfigByRank(poll_id, nextRank)
    return nextQuestion ? getPollStep(poll_id, nextQuestion.q_id) : null
}

export const getPreviousPollStep = (poll_id, current_question_id) => {
    const question = getQuestionConfigById(poll_id, current_question_id)
    const rank = question.q_order
    const previousRank = rank - 1
    const previousQuestion = getQuestionConfigByRank(poll_id, previousRank)
    return previousQuestion ? getPollStep(poll_id, previousQuestion.q_id) : null
}

export const getCurrentPollStep = (poll_id) => {
    const pollJson = window.localStorage.getItem(prefix(poll_id))
    const poll = pollJson ? JSON.parse(pollJson) : { answers: {}}
    const questionIds = Object.keys(poll.answers)
    const questions = questionIds.map((questionId) => poll.answers[questionId])
    const answers = _.sortBy(questions, ['q_order'])

    if (answers && answers.length > 0) {
        const lastAnswer = answers[answers.length - 1]
        const pollConfig = getPollConfigById(poll_id)
        const numberOfQuestion = pollConfig.questions.length
        const isLastQuestion = answers.length === numberOfQuestion

        if (isLastQuestion) return getPollStep(poll_id, lastAnswer.question_id)
        return getNextPollStep(poll_id, lastAnswer.question_id)
    }

    return {
        config: getQuestionConfigByRank(poll_id, 1)
    }
}

export const getFirstPollStep = (poll_id) => {
    const pollJson = window.localStorage.getItem(prefix(poll_id))
    const poll = pollJson ? JSON.parse(pollJson) : { answers: {}}
    const questionIds = Object.keys(poll.answers)
    const questions = questionIds.map((questionId) => poll.answers[questionId])
    const answers = _.sortBy(questions, ['q_order'])

    if (answers && answers.length > 0) {
        const firstAnswer = answers[0]
        return getPollStep(poll_id, firstAnswer.question_id)
    }

    return {
        config: getQuestionConfigByRank(poll_id, 1)
    }
}

export const getLastPollStep = (poll_id) => {
	const pollJson = window.localStorage.getItem(prefix(poll_id))
    const poll = pollJson ? JSON.parse(pollJson) : { answers: {} }
	const pollConfig = getPollConfigById(poll_id)
	const questions_config = _.sortBy(pollConfig.questions, ['q_order'])
	const lastQuestionConfig = questions_config[questions_config.length - 1]

	return {
		config: lastQuestionConfig,
		data: poll.answers[lastQuestionConfig.q_id]
	}
}

export const getPollData = (poll_id) => {
    const pollJson = window.localStorage.getItem(prefix(poll_id))
    const pollConfig = getPollConfigById(poll_id)

    if (pollJson && pollConfig) {
        const poll = JSON.parse(pollJson)
        const questionIds = Object.keys(poll.answers)
        const answers = questionIds.map((questionId) => poll.answers[questionId])

        return {
            poll_id: pollConfig.poll_id,
            answers
        }
    }

    return null
}

export const isFirstStep = (poll_id, question_id) => {
    const question = getQuestionConfigById(poll_id, question_id)
    return question.q_order === 1
}

export const isLastStep = (poll_id, question_id) => {
    const pollConfig = getPollConfigById(poll_id)
    const question = getQuestionConfigById(poll_id, question_id)
    const numberOfQuestions = pollConfig.questions.length
    return question.q_order === numberOfQuestions
}

export const pollSubmitted = (poll_id) => {
    const pollJson = window.localStorage.getItem(prefix(poll_id))

    if (pollJson) {
        const poll = JSON.parse(pollJson)
        poll.submitted = true
        window.localStorage.setItem(prefix(poll_id), JSON.stringify(poll))
    }
}

export const hasAnswers = (poll_id) => {
    const pollJson = window.localStorage.getItem(prefix(poll_id))
    const poll = pollJson ? JSON.parse(pollJson) : { answers: {}}
    return poll.answers && Object.keys(poll.answers).length > 0
}

export const isPollFilled = (poll_id) => {
    const pollConfig = getPollConfigById(poll_id)
    const numberOfQuestion = pollConfig.questions.length
    const pollJson = window.localStorage.getItem(prefix(poll_id))
    const poll = pollJson ? JSON.parse(pollJson) : { answers: {}}
    const questionIds = Object.keys(poll.answers)
    const questions = questionIds.map((questionId) => poll.answers[questionId])
    return questions.length === numberOfQuestion
}

/************************************************ VALIDATION ************************************************/

export const isPollIdValid = (poll_id) => {
	const pollConfig = getPollConfigById(poll_id)
	if (!poll_id || !pollConfig || !pollConfig.poll_id) return { valid: false, error: ERRORS.NO_ID }
	return { valid: true }
}

export const isPollProtected = (poll_id, poll_code) => {
	const pollConfig = getPollConfigById(poll_id)
	const config_code = pollConfig.poll_code

	if (config_code && !poll_code) {
		return { valid: false, error: ERRORS.NO_CODE }
	} else if (config_code && poll_code && config_code !== poll_code) {
		return { valid: false, error: ERRORS.CODE }
	} else if (pollConfig.poll_restricted && !isSessionValid()) {
		return { valid: false, error: ERRORS.LOGIN }
	}

	return { valid: true, storeCode: true }
}

export const isPollValid = (poll_id) => {
	const pollJson = window.localStorage.getItem(prefix(poll_id))
	const { poll_open_date, poll_close_date} = getPollConfigById(poll_id)
	const open_date = moment(poll_open_date, 'YYYY/MM/DD HH:mm')
	const close_date = moment(poll_close_date, 'YYYY/MM/DD HH:mm')

	if (poll_open_date && poll_close_date) {
        if (moment().isAfter(close_date)) return {
			valid: false,
            error: ERRORS.OUTDATED_AFTER
        }
        if (moment().isBefore(open_date)) return {
			valid: false,
            error: ERRORS.OUTDATED_BEFORE
        }
    }

    if (poll_open_date) {
        if (moment().isBefore(open_date)) return {
			valid: false,
            error: ERRORS.OUTDATED_BEFORE
        }
    }

    if (poll_close_date) {
        if (moment().isAfter(close_date)) return {
			valid: false,
            error: ERRORS.OUTDATED_AFTER
        }
    }

	if (pollJson) {
        const poll = JSON.parse(pollJson)
        if (poll.submitted) return {
			valid: false,
			error: ERRORS.SUBMITTED
		}
		else if (isPollFilled(poll_id)) {
			return {
				valid: false,
				error: ERRORS.FILLED
			}
		}
    }

	return { valid: true }
}

/************************************************ END VALIDATION ************************************************/
