import {
    DATA_TYPE_PAPERS
} from 'data/config/dataConfig';

import {
    CONFIG_JSON_LOADED,
    ALL_FAVORITES_DELETED,
    DATA_ASSETS_UPDATED,
    ITEM_FETCHED,
    HAS_NAVIGATED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_FAVORITE,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    SEARCH_PERFORMED,
    CLEAR_SEARCH_RESULTS,
    FETCH_FAVORITES,
    NOTE_DELETED,
    NOTE_SAVED
} from 'src/store/actionTypes';

import {
    configJsonLoaded,
    dataUpdated,
    getPageCommonDefaultState,
    togglePageAfterNavigation,
    itemFetched,
    langChanged,
    profileChanged,
    setIsFavoriteFalse,
    toggleFavorite,
    toggleLocationStatus,
    toggleMenu,
    updatePageState,
    searchPerformed,
    clearSearchResults,
    fetchFavorites,
    noteDeleted,
    noteSaved
} from 'src/store/reducers/commons';

import { PAPER_PAGE_KEY } from 'src/pages/pagesKeys';

const USED_TABLES = [ DATA_TYPE_PAPERS ];

const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(PAPER_PAGE_KEY), {
    isPending  : true
});

const _itemFetched = (state, action) => itemFetched(state, action, DATA_TYPE_PAPERS);

export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case HAS_NAVIGATED: return togglePageAfterNavigation(state, PAPER_PAGE_KEY, action.pageKey);
        case ALL_FAVORITES_DELETED: return setIsFavoriteFalse(state);
        case DATA_ASSETS_UPDATED: return dataUpdated(state, action, USED_TABLES);
        case ITEM_FETCHED: return _itemFetched(state, action);
        case LANG_CHANGED: return langChanged(state, action);
        case PROFILE_CHANGED: return profileChanged(state, action, PAPER_PAGE_KEY);
        case TOGGLE_FAVORITE: return toggleFavorite(state, action, DATA_TYPE_PAPERS);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU: return toggleMenu(state, action, PAPER_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, PAPER_PAGE_KEY);
        case SEARCH_PERFORMED : return searchPerformed(state, action, PAPER_PAGE_KEY);
        case CLEAR_SEARCH_RESULTS : return clearSearchResults(state);
        case FETCH_FAVORITES: return fetchFavorites(state, action);
        case NOTE_DELETED: return noteDeleted(state, action);
        case NOTE_SAVED: return noteSaved(state, action);

        default: return state;
    }
};
