
import config from 'data/config/config';
import { DATA_TYPE_PARTICIPANTS } from 'data/config/dataConfig';

import * as ParticipantsWsTaiga from 'src/core/webservices/taiga/ParticipantsWS';
import { parseDate } from 'src/core/webservices/taiga/helpers';
import { TAIGA } from 'src/core/webservices/WsProviders';
import * as UserDataService from 'src/core/user-data/UserDataService';
import { appendOrUpdateSortedAndTransformedData } from 'src/core/data-and-assets/Db';
import { getAll } from 'src/core/query/Query';
import { getBindedActions } from 'src/store/bindedActions';


export const ERRORS = {
    NETWORK: 'PARTICIPANTS_WS_NETWORK_ERROR',
    UNKNOWN: 'PARTICIPANTS_WS_UNKNOWN_ERROR',
};

const LOG_PREF = '[ParticipantsService] ';


export function isSameUser(participant) {
    if (participant) {
        let userData = UserDataService.getUserData();
        if (userData) {
            return userData.id === participant.id;
        }
    }
    return false;
}

export function getContactDate(participant) {
    if (participant) {
        let userData = UserDataService.getUserData();
        if (userData) {
            let contactData = (userData.contactedParticipants || []).find(data => data.contactId === participant.id);
            if (contactData) {
                // string such as "2019-10-25 09:00:39"
                return parseDate( contactData.contactDate );
            }
        }
    }
    return null;
}


let lastFetchDate;

function hasDataExpired() {
    if (!lastFetchDate) {
        return true;
    }
    let delaySinceLastFetch = lastFetchDate && new Date().getTime()-lastFetchDate;
    return delaySinceLastFetch > config.PARTICIPANTS.DATA_EXPIRE_DELAY;
}


/**
 * Fetch participants from web service
 * @param  {Function} cb
 */
export function getParticipants(cb, skipCache) {
    // let cbExecuted;
    if (typeof cb === 'function') {
        cb(null, getAll(DATA_TYPE_PARTICIPANTS) || []);
    }
    if (!hasDataExpired() && !skipCache) {
        return;
    }

    function next(error, data) {
        if (data) {
            lastFetchDate = new Date().getTime();
            appendOrUpdateSortedAndTransformedData(data, DATA_TYPE_PARTICIPANTS);
            getBindedActions().dataAssetsUpdated([ DATA_TYPE_PARTICIPANTS ]);
        }
        // if (typeof cb === 'function') {
        //     cb(error, data);
        // }
    }

    switch (config.PARTICIPANTS.PROVIDER) {

        case TAIGA:
            ParticipantsWsTaiga.getParticipants(next);
            break;

        default:
            console.error(LOG_PREF+'Unexpected web service provider: '+config.PARTICIPANTS.PROVIDER);
    }
}
if (config.ENV === 'dev') {
    global.getParticipants = getParticipants;
}


/**
 * Trigger participant contact by calling web service
 * @param  {Function} cb
 */
export function contactParticipant(participantId, cb) {

    function next(error) {
        getBindedActions().contactRequestPerformed({
            id: participantId,
            dataType: DATA_TYPE_PARTICIPANTS,
            ws: config.PARTICIPANTS.PROVIDER,
            error: error,
        });

        if (typeof cb === 'function') {
            cb(error);
        }
        getParticipants(null, true);
    }

    switch (config.PARTICIPANTS.PROVIDER) {

        case TAIGA:
            ParticipantsWsTaiga.contactParticipant(participantId, next);
            break;

        default:
            console.error(LOG_PREF+'Unexpected web service provider: '+config.PARTICIPANTS.PROVIDER);
    }
}
if (config.ENV === 'dev') {
    global.contactParticipant = contactParticipant;
}
