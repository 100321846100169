
// Libs
import React, { PureComponent } from 'react';
import PropTypes                from 'prop-types';
import { AlertDialog }          from 'react-onsenui';
import { bindActionCreators }   from 'redux';
import { connect, Provider }              from 'react-redux';

// app modules
import TypeBar from 'src/components/type-bar/TypeBar';
import List    from 'src/components/list/List';
import * as actions from 'src/store/actions';


import '../listDialog.scss';
import '../genericModal.scss';


export const COMPONENT_KEY = 'DataListDialog';
export const CONTAINER_DOM_ID = 'data-list-dialog-container';


const DEFAULT_DIALOG_PROPS = {
    isCancelable: true,
    animation   : 'default', // or 'none', no other choice sadly
};

let store;
export function setReduxStore(_store) {
    store = _store
}

let _containerStyle;
/**
 * The point is to create a new object only if value is different,
 * to avoid useless renders
 * @return {object}
 */
const getContainerStyle = () => {
    let maxHeight = document.documentElement.clientHeight*0.75+'px';

    if (!_containerStyle || _containerStyle.maxHeight !== maxHeight) {
        _containerStyle = {
            maxHeight: maxHeight,
        };
    }
    return _containerStyle;
};


/**
 * Display a dialog (modal) allowing user to change current language for the whole app
 * @param  {object} props
 */
class DataListDialog extends PureComponent {


    closeDialog = () => {
        this.props.actions.hideDataListDialog();
    }


    getTypedLists() {
        let typedLists = [];

        if (this.props.items) {

            let dataTypes = Object.keys(this.props.items)
                                    .filter(dataType => this.props.items[dataType].length > 0);

            dataTypes.forEach(dataType => {

                // Ignore this dataType if it has no entry
                if (this.props.items[dataType].length) {

                    const listProps = {
                        items            : this.props.items[dataType],
                        favorites        : this.props.favorites,
                        dataType         : dataType,
                        actions          : this.props.actions,
                        labels           : this.props.labels,
                        isPending        : this.props.isPending,
                        onElementSelected: this.closeDialog,
                        displayFavorites : this.props.favIconDisabled !== true,
                        showGoToButton   : this.props.showGoToButton === true,
                        pageKey          : this.props.pageKey,
                        contextualPlaceId: this.props.placeId,
                    };

                    let count = listProps.items ? listProps.items.length : 0;

                    typedLists.push(
                        <TypeBar
                                key={dataType}
                                label={this.props.labels.data[dataType][count > 1 ? 'plural' : 'singular']}
                                count={count}
                                isClosed={dataTypes.length > 1 && count > this.props.closedIfCountAbove}>
                            <Provider store={store}>
                                <List {...listProps} />
                            </Provider>
                        </TypeBar>
                    );
                }
            });
        }

        return typedLists;
    }


    render() {
        let dialogProps = Object.assign({}, DEFAULT_DIALOG_PROPS, {
            isOpen  : this.props.isOpen,
            onCancel: this.props.actions.hideDataListDialog, // important, to update `isOpen` value in all cases
        });

        let title = this.props.title;
        if (!title) {
            let dataTypes = Object.keys(this.props.items || {});
            if (dataTypes.length === 1) {
                title = this.props.labels.data[dataTypes[0]].plural;
            }
        }

        return (
            <AlertDialog
                {...dialogProps}
                className="list-dialog content-font">

                <div className="list-dialog-container" style={getContainerStyle()}>
                    <div className="generic-modal title-font">{title}</div>
                    <div className="list-dialog-content">
                        { this.getTypedLists() }
                    </div>
                </div>

            </AlertDialog>
        );
    }

}

DataListDialog.propTypes = {
    showGoToButton: PropTypes.bool,
    isOpen   : PropTypes.bool.isRequired,
    items    : PropTypes.object,
    favorites: PropTypes.object,
    title    : PropTypes.string,
    placeId  : PropTypes.number,
    pageKey  : PropTypes.string,
    labels   : PropTypes.object.isRequired,
    actions  : PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => state[COMPONENT_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataListDialog);
