
// Libs
import React           from 'react';
import PropTypes       from 'prop-types';
import { AlertDialog } from 'react-onsenui';
import { connect }     from 'react-redux';

// app modules
import {
    MESSAGE_DISMISSED,
    MESSAGE_ACTIONED,
} from 'src/pages/inbox/inboxReducer';

import './MessageAlert.scss';


export const COMPONENT_KEY = 'MessageAlert';
export const CONTAINER_DOM_ID = 'message-alert-container';


const DEFAULT_DIALOG_PROPS = {
    isCancelable: true,
    animation   : 'default', // or 'none', no other choice sadly
};


function MessageAlert(props) {

    let dialogProps = Object.assign({}, DEFAULT_DIALOG_PROPS, {
        isOpen    : props.isOpen,
        cancelable: props.cancelable,
        onCancel  : props.onCancel, // important, to update `isOpen` value in all cases
    });

    return (
        <AlertDialog {...dialogProps}>
            <div className="generic-modal content-font notif-dialog">

                <div>{ props.isOpen && props.message &&
                    <div>
                        <div className="title-font">{ props.message.title }</div>
                        <p>{ props.message.content }</p>
                    </div>
                }</div>

                <div className="generic-btn-container">
                    <div className="generic-btn-cancel" onClick={props.onCancel}>{ props.labels.inbox.dismiss }</div>
                    <div className="generic-btn cta-modal-btn" onClick={props.onAccept}>{ props.labels.inbox.confirmNotif }</div>
                </div>
            </div>
        </AlertDialog>
    );
}


MessageAlert.propTypes = {
    isOpen    : PropTypes.bool.isRequired,
    cancelable: PropTypes.bool.isRequired,
    onCancel  : PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {

    const notif = state.Inbox.notif;
    let message = null;
    if ( notif !== null ) {
        message = state.Inbox.byDate.filter(message => message.id === notif)[0];
    }

    return {
        isOpen: state.Inbox.notif !== null && typeof message === 'object' && message !== null,
        cancelable: true,
        message,
        labels: state.Inbox.labels,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCancel: () => dispatch({
            type: MESSAGE_DISMISSED,
        }),
        onAccept: () => dispatch({
            type: MESSAGE_ACTIONED,
        })
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageAlert);
