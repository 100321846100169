// Libs
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import config from 'data/config/config';

// App modules
import { FAVORITES_PAGE_KEY, INBOX_PAGE_KEY, SEARCH_PAGE_KEY, LOGIN_PAGE_KEY, USER_DATA_PAGE_KEY } from 'src/pages/pagesKeys';

import './HomeToolbar.scss';


class HomeToolbar extends PureComponent {

  getButtons = () => {
    let toolbar = [];

    let i = 0

    // add Login
    if (config.LOGIN.FEATURE_ENABLED) {
        toolbar.push({
          key: i++,
          iconClassName: 'fa fa-user ' + (this.props.isLoggedIn ? 'connected' : ''),
          label: this.props.labels.common.login,
          action: () => {
            this.props.actions.navigate(this.props.isLoggedIn ? USER_DATA_PAGE_KEY : LOGIN_PAGE_KEY);
          }
        });
    }
    // Black list of buttons just to avoid side effect on older projects
    if(!this.props.disabledButtons || (this.props.disabledButtons && this.props.disabledButtons.indexOf("favorites") === -1)){
      toolbar.push({
        key: i++,
        iconClassName: 'icon-font',
        iconContent: 'e',
        label: this.props.labels.common.goToFavorites,
        action: () => {
          this.props.actions.navigate(FAVORITES_PAGE_KEY);
        }
      })
    }
    if(!this.props.disabledButtons || (this.props.disabledButtons && this.props.disabledButtons.indexOf("inbox") === -1)){
      toolbar.push({
        key: i++,
        iconClassName: 'fa fa-envelope',
        label: this.props.labels.common.goToInbox,
        //                badge: global.isCordovaContext ? this.props.inboxCount : null,
        badge: null,
        action: () => {
          this.props.actions.navigate(INBOX_PAGE_KEY);
        }
      })
    }
    if(!this.props.disabledButtons || (this.props.disabledButtons && this.props.disabledButtons.indexOf("search") === -1)){
      toolbar.push({
        key: i++,
        iconClassName: 'fa fa-search',
        label: this.props.labels.common.goToSearch,
        action: () => {
          this.props.actions.navigate(SEARCH_PAGE_KEY);
        }
      })
    }
    if(!this.props.disabledButtons || (this.props.disabledButtons && this.props.disabledButtons.indexOf("menu") === -1)){
      toolbar.push({
        key: i++,
        iconClassName: 'fa fa-bars',
        label: this.props.labels.common.openMenu,
        action: () => {
          this.props.actions.openMenu(this.props.associatedPageKey);
        }
      })
    }


    return toolbar;
  }

  render() {
    return (
      <div id="home-toolbar">
        { this.getButtons().map(button => (
            <div
                 key={ button.key }
                 className="home-toolbar-button"
                 onClick={ button.action }
                 title={ button.label }>
              { typeof button.badge === 'number' && <span className="home-toolbar-button-badge">{ button.badge }</span> }
              <span className={ button.iconClassName }>{ button.iconContent || '' }</span>
            </div>
          )) }
      </div>
      );
  }
}

HomeToolbar.propTypes = {
  labels: PropTypes.object.isRequired,
  associatedPageKey: PropTypes.string.isRequired,
  actions: PropTypes.object.isRequired,
  isLoggedIn: PropTypes.bool,
  inboxCount: PropTypes.number,
};

export default HomeToolbar;