
import config from 'data/config/config';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { ERRORS } from 'src/core/login/LoginService';
import STATUSES from '../statuses';
import dummyToken from './dummyToken';
import { transformData } from './helpers';


const LOG_PREF = '[LoginWS Taiga] ';


export function performLogin(account, cb) {
    const url = config.LOGIN.WS_URL+`/tgServices/BlockchainAppWs/login.htm?login=${account.username}&password=${account.password}`;

    let opts = {
        method: 'GET',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        withCredentials: true, // use cookie (the browser takes care of it)
    };

    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onLoginSuccess(response) {
            switch(response.status) {
                case STATUSES.SUCCESS:
                    cb(
                        null, // error code
                        dummyToken, // token
                        transformData.apiToApp.user(response.datas), // userdata
                    );
                    break;

                case STATUSES.ERROR:
                    // expected response.datas.message value "Wrong login or password."
                    cb(
                        ERRORS.LOGIN, // error code
                        null, // token
                    );
                    break;

                default: console.error(LOG_PREF+'Unexpected response status: '+response.status);
            }
        },
        function onLoginFailure(error, message) {
            cb(ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}

if (config.ENV === 'dev') {
    global.performLoginUsingTaigaWs = performLogin;
}
