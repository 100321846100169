
import config from 'data/config/config';

import * as UserDataWsNodeBackend from 'src/core/webservices/nodebackend/UserDataWS';
import * as UserDataWsTaiga from 'src/core/webservices/taiga/UserDataWS';
import { TAIGA, NODEBACKEND } from 'src/core/webservices/WsProviders';
import { getToken } from 'src/core/login/LoginPersistence';
import { getBindedActions } from 'src/store/bindedActions';


const LOCALSTORAGE_USER_PROFILE_KEY = 'userData';

export const ERRORS = {
    NETWORK: 'USERDATA_WS_NETWORK_ERROR',
    UNKNOWN: 'USERDATA_WS_UNKNOWN_ERROR',
};

const LOG_PREF = '[UserDataService] ';


export function getUserData() {
    return JSON.parse(window.localStorage.getItem(LOCALSTORAGE_USER_PROFILE_KEY));
}

export function setUserData(value) {
    window.localStorage.setItem(LOCALSTORAGE_USER_PROFILE_KEY, JSON.stringify(value));
}

export function clear() {
    window.localStorage.removeItem(LOCALSTORAGE_USER_PROFILE_KEY);
    getBindedActions().userDataUpdated(null);
}


export const isUserDataEnoughSet = profile => !!(
    profile
    && profile.firstName
    && profile.lastName
    // && profile.title
    // && profile.organisation
    && profile.position
    && profile.email
    && profile.phone
);


export function remoteSaveUserData(newFields, updateRemote, cb) {
    let currentUserData = getUserData();
    if (currentUserData === null) {
        currentUserData = {};
    }

    let newUserData = Object.assign(currentUserData, newFields)

    function next() {
        // local update
        setUserData(newUserData);

        if (typeof cb === 'function') {
            cb(null, newUserData);
        }
    }

    // remote update
    if (!updateRemote) {
        next();
    } else {
        switch (config.USER_DATA.PROVIDER) {

            case TAIGA:
                UserDataWsTaiga.saveUserData(newFields, error => {
                    if (error) {
                        cb(error);
                    } else {
                        // Update local only if remote has been successful ??
                        next();
                    }
                });
                break;

            default:
                console.error(LOG_PREF+'Unexpected web service provider: '+config.USER_DATA.PROVIDER);
        }
    }
}

/**
 * Remove empty properties (to avoid overiding values set by the user)
 * @param  {object} userData
 * @return {object}
 *
function cleanData(userData) {
    let cleanedUserData = {};
    Object.keys(userData).forEach(function(key) {
        let value = userData[key];
        if (value !== null && typeof value !== 'undefined' && value !== '') {
            cleanedUserData[key] = value;
        }
    });
    return cleanedUserData;
}*/


/**
 * @param  {object} userData
 * @return {object} normalized data (= cleaned and transformed to app expected format)
 */
export function updateUserDataFromApiData(userData) {
    let newUserData = Object.assign({}, getUserData(), userData)
    setUserData(newUserData);
    getBindedActions().userDataUpdated(newUserData);
}


/**
 * Retrieve profile information from token
 * @param  {string} token
 * @param  {function} successCb
 * @param  {function} failureCb
 */
export function getUserDataFromAPI(successCb, failureCb) {
    switch (config.USER_DATA.PROVIDER) {

        case NODEBACKEND:
            let sessionToken = getToken();
            if (!sessionToken) {
                console.error(LOG_PREF+'Cannot get profile information from API because session \'token\' is missing');
                return;
            }
            UserDataWsNodeBackend.getUserData(sessionToken, getUserDataCallback);
            break;

        case TAIGA:
            UserDataWsTaiga.getUserData(getUserDataCallback);
            break;

        default:
            console.error(LOG_PREF+'Unexpected web service provider: '+config.USER_DATA.PROVIDER);
    }
}

function getUserDataCallback(errorCode, userdata) {
    if (!errorCode && userdata) {
        updateUserDataFromApiData(userdata);
    }
}