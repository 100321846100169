import { PARTICIPANT_PAGE_KEY } from 'src/pages/pagesKeys.js';

import ParticipantPage        from 'src/pages/participant/ParticipantPage';
import participantPageReducer from 'src/pages/participant/participantPageReducer';

import { isSessionValid }  from 'src/core/login/LoginService';

export default {
    key      : PARTICIPANT_PAGE_KEY,
    path     : '/participant',
    component: ParticipantPage,
    elId     : 'participant-page',
    getReduxReducer: (state, action) => participantPageReducer(state[PARTICIPANT_PAGE_KEY], action),
    isGranted: () => isSessionValid(),
};
