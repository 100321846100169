
import config from 'data/config/config';

import {
    DATA_TYPE_EXHIBITORS,
    DATA_TYPE_COUNTRIES
} from 'data/config/dataConfig';

import { AUTOSHOW_POI_ON_MAP } from 'data/config/pagesTwoColumnsConfig';

import {
    CONFIG_JSON_LOADED,
    POLL_CONFIG_LOADED,
    ALL_FAVORITES_DELETED,
    APPOINTMENT_REQUEST_SEND_RESULT,
    APPOINTMENT_REQUEST_SENT,
    DATA_ASSETS_UPDATED,
    HAS_NAVIGATED,
    ITEM_FETCHED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    TOGGLE_FAVORITE,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
    SEARCH_PERFORMED,
    CLEAR_SEARCH_RESULTS,
    FETCH_FAVORITES,
    NOTE_DELETED,
    NOTE_SAVED
} from 'src/store/actionTypes';

import {
    configJsonLoaded,
    pollConfigLoaded,
    dataUpdated,
    getPageCommonDefaultState,
    togglePageAfterNavigation,
    itemFetched,
    langChanged,
    profileChanged,
    setIsFavoriteFalse,
    toggleFavorite,
    toggleLocationStatus,
    toggleMenu,
    updatePageState,
    searchPerformed,
    clearSearchResults,
    fetchFavorites,
    noteDeleted,
    noteSaved
} from 'src/store/reducers/commons';

import { getRequestStatus } from 'src/core/appointment-request/AppointmentRequestService';

import { EXHIBITOR_PAGE_KEY } from 'src/pages/pagesKeys';


const USED_TABLES = [ DATA_TYPE_EXHIBITORS, DATA_TYPE_COUNTRIES ];


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(EXHIBITOR_PAGE_KEY), {
    isPending  : true,
    autoShowPoi: AUTOSHOW_POI_ON_MAP[EXHIBITOR_PAGE_KEY] === true,
    appointmentRequestStatus: {
        isEnabled: config.APPOINTMENT_REQUEST.FEATURE_ENABLED === true,
    },
});

function _itemFetched(state, action) {
    let newState = itemFetched(state, action, DATA_TYPE_EXHIBITORS);

    if (newState !== state) {
        // State updated

        if (state.appointmentRequestStatus.isEnabled) {
            // Check if an appointment request has already been sent
            let originalId = action.item.original_id;
            let appointmentRequestStatus = getRequestStatus(originalId, DATA_TYPE_EXHIBITORS);

            return __updateAppointmentRequestStatus(newState, appointmentRequestStatus);
        }
    }
    return newState;
}

/**
 * Request to the backend has been sent
 */
function _appointmentRequestSent(state, action) {
    if (action.dataType === DATA_TYPE_EXHIBITORS && action.dataId === state.id) {
        return __updateAppointmentRequestStatus(state, null, true);
    }
    return state;
}

/**
 * Request to the backend is over
 */
function _appointmentRequestSendResult(state, action) {
    if (action.dataType === DATA_TYPE_EXHIBITORS && action.dataId === state.id) {
        return __updateAppointmentRequestStatus(state, action.status);
    }
    return state;
}

/**
 * Common function to update `appointmentRequestStatus`
 * @param  {object}  state
 * @param  {object}  status
 * @param  {boolean} ongoing
 */
function __updateAppointmentRequestStatus(state, status, ongoing=false) {
    let newState = Object.assign({}, state);
    newState.appointmentRequestStatus = Object.assign({}, state.appointmentRequestStatus)

    if (status && status.date) {
        // Request success
        newState.appointmentRequestStatus.date = status.date;
        newState.appointmentRequestStatus.userEmail = status.userEmail;
        newState.appointmentRequestStatus.userPhone = status.userPhone;
    } else {
        // Ongoing or request failed
        newState.appointmentRequestStatus.date = null;
        newState.appointmentRequestStatus.userEmail = null;
        newState.appointmentRequestStatus.userPhone = null;
    }
    newState.appointmentRequestStatus.ongoing = ongoing;

    return newState;
}


export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case POLL_CONFIG_LOADED : return pollConfigLoaded(state, action);
        case ALL_FAVORITES_DELETED: return setIsFavoriteFalse(state);
        case APPOINTMENT_REQUEST_SEND_RESULT: return _appointmentRequestSendResult(state, action);
        case APPOINTMENT_REQUEST_SENT: return _appointmentRequestSent(state, action);
        case DATA_ASSETS_UPDATED: return dataUpdated(state, action, USED_TABLES);
        case HAS_NAVIGATED: return togglePageAfterNavigation(state, EXHIBITOR_PAGE_KEY, action.pageKey);
        case ITEM_FETCHED: return _itemFetched(state, action);
        case LANG_CHANGED: return langChanged(state, action);
        case PROFILE_CHANGED: return profileChanged(state, action, EXHIBITOR_PAGE_KEY);
        case TOGGLE_FAVORITE: return toggleFavorite(state, action, DATA_TYPE_EXHIBITORS);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU: return toggleMenu(state, action, EXHIBITOR_PAGE_KEY);
        case UPDATE_PAGE_STATE: return updatePageState(state, action, EXHIBITOR_PAGE_KEY);
        case SEARCH_PERFORMED : return searchPerformed(state, action, EXHIBITOR_PAGE_KEY);
        case CLEAR_SEARCH_RESULTS : return clearSearchResults(state);
        case FETCH_FAVORITES: return fetchFavorites(state, action);
        case NOTE_DELETED: return noteDeleted(state, action);
        case NOTE_SAVED: return noteSaved(state, action);

        default: return state;
    }
};
