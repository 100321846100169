import moment from 'moment';


// e.g "2019-10-25 09:00:39"
// see https://momentjs.com/docs/#/parsing/string-format/
const STRING_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const parseDate = s => moment(s, STRING_DATE_FORMAT).toDate()


/*
    {
    x "id": "a4beeea9-6eea-e911-80e2-005056ae0696",
      "type": "",
    x "civility": "",
    x "firstName": "Séga",
    x "lastName": "SYLLA",
    x "email": "ssylla@leni.fr",
      "profilePicture": [],
    x "phoneNumber": "0320000000",
      "linkedIn": "https://fr.linkedin.com/",
    x "role": "Dev",
    x "visible": true,
    x "contactable": true,
    x "organizationName": "LENI",
      "organizationLogo": [],
      "organisationWebsite": "http://www.leni2.fr",
    x "contactedParticipants": [
        {
          "contactId": "7a8f1c7f-6fea-e911-80e2-005056ae0696",
          "contactDate": "2019-10-25 09:00:39"
        },
        {
          "contactId": "3e5619d7-f6ef-e911-80e2-005056ae0696",
          "contactDate": "2019-10-25 09:16:36"
        },
        {
          "contactId": "14700dc7-0ef0-e911-80e2-005056ae0696",
          "contactDate": "2019-10-29 11:04:06"
        }
      ],
      "events": [],
      "externalRessources": []
    }
*/

/**
 * Transform user data from Taiga format to app format
 */
const transformUserDataApiToApp = userData => ({
    id          : userData.id,
    title       : userData.civility,
    firstName   : userData.firstName,
    lastName    : userData.lastName,
    organisation: userData.organizationName,
    position    : userData.role,
    email       : userData.email,
    phone       : userData.phoneNumber,
    visible     : userData.visible,
    contactable : userData.contactable,
    contactedParticipants: userData.contactedParticipants,
    profilePicture: (typeof userData.profilePicture === 'string' && userData.profilePicture.length > 0 ? userData.profilePicture : null),
    socialNetworkProfiles: userData.socialNetworkProfiles,
    events      : userData.events,
})

/**
 * Transform user data from app format to Taiga format
 */
const transformUserDataAppToApi = userData => ({
    id                   : userData.id,
    civility             : userData.title,
    firstName            : userData.firstName,
    lastName             : userData.lastName,
    organizationName     : userData.organisation,
    role                 : userData.position,
    email                : userData.email,
    phoneNumber          : userData.phone,
    visible              : userData.visible,
    contactable          : userData.contactable,
    contactedParticipants: userData.contactedParticipants,
    profilePicture       : userData.profilePicture,
    socialNetworkProfiles: userData.socialNetworkProfiles,
    events               : userData.events,
})




export const transformData = {
    apiToApp: {
        user: transformUserDataApiToApp,
    },
    appToApi: {
        user: transformUserDataAppToApi,
    },
}
