
import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import AppToolbar from 'src/components/app-toolbar/AppToolbar';
import { INBOX_PAGE_KEY } from 'src/pages/pagesKeys';
import Menu from 'src/components/menu/Menu';

import MessageList from 'src/components/inbox/MessageList';

import * as actions from 'src/store/actions';

import './InboxPage.scss';


class InboxPage extends React.Component {

    static propTypes = {
        // array of messages
        byDate          : PropTypes.array,
        // callback used when an item is clicked
        onClick           : PropTypes.func,

        // Common
        isMenuOpen        : PropTypes.bool.isRequired,
        profile           : PropTypes.string,
        labels            : PropTypes.object.isRequired,
        actions           : PropTypes.object.isRequired,
        isActive          : PropTypes.func.isRequired,
        setDocumentContext: PropTypes.func.isRequired,
        isLocationEnabled : PropTypes.bool,
        // toolbar
        hasToolbar           : PropTypes.bool,
        homeButtonInToolbar  : PropTypes.bool,
        backButtonInToolbar  : PropTypes.bool,
        searchButtonInToolbar: PropTypes.bool,
        favButtonInToolbar   : PropTypes.bool,
        menuButtonInToolbar  : PropTypes.bool,
    }

    constructor(props) {
        super(props);
        this.pageKey = INBOX_PAGE_KEY;
    }

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.props.labels.inbox.title);
        }
    }

    componentDidMount() {
        this.setDocumentContext();
    }
    componentDidUpdate() {
        this.setDocumentContext();
    }

    render () {
        if (!this.props.isVisible) return null;

        return (
            <Menu
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                actions={this.props.actions}
                options={{ isOpen: this.props.isMenuOpen }}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}
                isLocationEnabled={this.props.isLocationEnabled}>

                <AppToolbar
                    labels={this.props.labels}
                    isDisplayed={this.props.hasToolbar}
                    pageKey={this.pageKey}
                    title={this.props.labels.inbox.title}
                    actions={this.props.actions}
                    profile={this.props.profile}
                    hasBackButton={this.props.backButtonInToolbar}
                    hasHomeButton={this.props.homeButtonInToolbar}
                    hasFavButton={this.props.favButtonInToolbar}
                    hasSearchButton={this.props.searchButtonInToolbar}
                    hasMenuButton={this.props.menuButtonInToolbar}>

                    { Array.isArray(this.props.byDate) !== true || this.props.byDate.length === 0
                        ? <div className="inbox-empty">{this.props.labels.inbox.empty}</div>
                        : <MessageList
                                messages={this.props.byDate}
                                onClick={this.props.onClick}
                                lang={this.props.labels.id} />
                    }
                </AppToolbar>
            </Menu>
        );
    }
}

export const mapStateToProps = state => state[INBOX_PAGE_KEY];

export function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch),
        onClick(action) {
            if (typeof action === 'function') {
                return action(dispatch);
            } else {
                console.error('Unexpected `action` argument: ', action);
            }
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InboxPage);
