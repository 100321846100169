
import config from 'data/config/config';

import { DATA_TYPE_EVENTS } from 'data/config/dataConfig';

import {
    CLEAR_SEARCH_RESULTS,
    CONFIG_JSON_LOADED,
    DATA_ASSETS_UPDATED,
    GROUPED_ITEMS_FETCHED,
    HAS_NAVIGATED,
    LANG_CHANGED,
    PROFILE_CHANGED,
    SEARCH_PERFORMED,
    SYNOPTIC_AGENDA_TAB_INDEX_UPDATE,
    TOGGLE_LOCATION_STATUS,
    TOGGLE_MENU,
    UPDATE_PAGE_STATE,
} from 'src/store/actionTypes';

import {
    clearSearchResults,
    configJsonLoaded,
    dataUpdated,
    getPageCommonDefaultState,
    groupedItemsFetched,
    langChanged,
    profileChanged,
    searchPerformed,
    toggleLocationStatus,
    toggleMenu,
    togglePageAfterNavigation,
    updateObject,
    updatePageState,
} from 'src/store/reducers/commons';

import { SYNOPTIC_AGENDA_PAGE_KEY } from 'src/pages/pagesKeys';
import { formatDayMonth } from 'src/core/Lang';


const USED_TABLES = [ DATA_TYPE_EVENTS ];


const getDefaultState = () => Object.assign({}, getPageCommonDefaultState(SYNOPTIC_AGENDA_PAGE_KEY), {
    isPending: true,
    index: 0,
    config: config.SYNOPTIC_AGENDA,
});

function ignoreEvent(event) {
    return !event.start_time || !event.end_time || !event.event_place_text;
}

function _groupedItemsFetched(state, action) {
    if (action.dataType !== DATA_TYPE_EVENTS) {
        return state;
    }

    // Apply generic behaviour
    let newState = groupedItemsFetched(state, action);

    // Prevent modifying by reference the content displayed in agenda view
    let groupedItems = JSON.parse(JSON.stringify(newState.groupedItems));

    let eventsByDayByPlaces = {};

    // Then also group events by place ids
    Object.keys(groupedItems).forEach(day => {
        let eventsByPlaces = {};

        // Filter events whose data is incomplete
        groupedItems[day].items = groupedItems[day].items.filter(event => !ignoreEvent(event));

        // Iterate on events
        groupedItems[day].items.forEach(event => {
            let placeKey = event.event_place_text;

            if (!eventsByPlaces[placeKey]) {
                eventsByPlaces[placeKey] = [];
            }
            eventsByPlaces[placeKey].push(event.id);
        });

        if (Object.keys(eventsByPlaces).length === 0) {
            delete groupedItems[day];
        } else {
            eventsByDayByPlaces[day] = eventsByPlaces;
        }
    });

    // Prepare data for tabs
    let tabsData = [];
    Object.keys(groupedItems).forEach((date, index) => {
        tabsData.push({
            key: date,
            label: formatDayMonth(parseInt(date, 10)),
        });
    });

    newState.agendaProps = { input: action.input };
    newState.groupedItems = groupedItems;
    newState.eventsByDayByPlaces = eventsByDayByPlaces;
    newState.index = 0;
    newState.tabsData = tabsData;
    return newState;
};


const _synopticAgendaTabIndexUpdate = (state, action) => (
    updateObject(state, { index: action.index })
)


function _updatePageState(state, action) {
    let newState = updatePageState(state, action, SYNOPTIC_AGENDA_PAGE_KEY);

    if (action.pageKey === SYNOPTIC_AGENDA_PAGE_KEY
            && JSON.stringify(action.props.agendaProps) !== JSON.stringify(state.agendaProps)) {

        newState.groupedItems = null;
        newState.eventsByDayByPlaces = null;
    }
    return newState;
}

export default (state = getDefaultState(), action) => {
    switch (action.type) {

        case CLEAR_SEARCH_RESULTS : return clearSearchResults(state);
        case CONFIG_JSON_LOADED: return configJsonLoaded(state);
        case DATA_ASSETS_UPDATED: return dataUpdated(state, action, USED_TABLES);
        case GROUPED_ITEMS_FETCHED: return _groupedItemsFetched(state, action);
        case HAS_NAVIGATED: return togglePageAfterNavigation(state, SYNOPTIC_AGENDA_PAGE_KEY, action.pageKey);
        case LANG_CHANGED: return langChanged(state, action);
        case PROFILE_CHANGED: return profileChanged(state, action, SYNOPTIC_AGENDA_PAGE_KEY);
        case SEARCH_PERFORMED : return searchPerformed(state, action, SYNOPTIC_AGENDA_PAGE_KEY);
        case SYNOPTIC_AGENDA_TAB_INDEX_UPDATE: return _synopticAgendaTabIndexUpdate(state, action);
        case TOGGLE_LOCATION_STATUS: return toggleLocationStatus(state, action);
        case TOGGLE_MENU: return toggleMenu(state, action, SYNOPTIC_AGENDA_PAGE_KEY);
        case UPDATE_PAGE_STATE: return _updatePageState(state, action);

        default: return state;
    }
};
