import { LIST_PAGE_KEY } from 'src/pages/pagesKeys.js';
import { DATA_TYPE_PARTICIPANTS } from 'data/config/dataConfig';

import ListPage        from 'src/pages/list/ListPage';
import listPageReducer from 'src/pages/list/listPageReducer';

import { isSessionValid }  from 'src/core/login/LoginService';


export default {
    key      : LIST_PAGE_KEY,
    path     : '/list',
    component: ListPage,
    elId     : 'list-page',
    getReduxReducer: (state, action) => listPageReducer(state[LIST_PAGE_KEY], action),
    isGranted: props => {
        if (Array.isArray(props.inputs)) {
            if (props.inputs.find(input => input.dataType === DATA_TYPE_PARTICIPANTS)) {
                return isSessionValid();
            }
        }
        return true;
    },
};
