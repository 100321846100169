
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE_NEWPRODUCTS } from 'data/config/dataConfig';
import { NEWPRODUCT_PAGE_KEY } from 'src/pages/pagesKeys';

import DetailExhibitor    from 'src/components/detail-exhibitor/DetailExhibitor';
import DetailFixedTitle   from 'src/components/detail-fixed-title/DetailFixedTitle';
import UseRemainingHeight from 'src/components/use-remaining-height/UseRemainingHeight';
import Description        from 'src/components/fiche/Description';
import WebsiteRow         from 'src/components/fiche/WebsiteRow';
import ShareButton        from 'src/components/share-button/ShareButton';
import NoteButton         from 'src/components/notes/NoteButton';

class NewProductContent extends PureComponent {

    setScroller = ref => {
        // Used by parent component
        this.scroller = ref;
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_NEWPRODUCTS,
            this.props.isFavorite
        );
    }

    render() {
        const title = this.props.item.title,
            logoUrl = this.props.item.photo_file_name,
            description = this.props.item.description;

        return (
            <div className="fiche newproduct content-font" style={{ height: this.props.height }}>

                {/* title */}
                <DetailFixedTitle
                    title={title}
                    isFav={this.props.isFavorite}
                    onFavClick={this.onClickOnFavIcon}
                    labels={this.props.labels}>
                    <NoteButton
                          itemTitle={this.props.getPageTitle(this.props.item, this.props.labels)} 
                          itemId={this.props.item.id}
                          dataType={DATA_TYPE_NEWPRODUCTS}
                          hasNote={this.props.hasNote} 
                          labels={this.props.labels}
                          actions={this.props.actions} />
                    <ShareButton
                        name={this.props.getPageTitle(this.props.item, this.props.labels)}
                        queryString={this.props.queryString}
                        description={description}
                        image={logoUrl}
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        pageKey={NEWPRODUCT_PAGE_KEY} />

                </DetailFixedTitle>

                <UseRemainingHeight ref={this.setScroller}>
                    <div className="content">
                        <div className="all-informations content-font">

                            <Description
                                logoFileName={logoUrl}
                                description={description}
                                isLogoFullWidth={this.props.isLogoFullWidth} />

                            {/* Exhibitor*/}
                            <DetailExhibitor
                                exhibitor={this.props.item.references.exhibitor}
                                actions={this.props.actions}
                                labels={this.props.labels} />

                            {/* Market date */}
                            { this.props.item.lump.date_market &&
                                <div className="free-row">
                                    <div className="prop-img">
                                        <div className="prop-left label">
                                            <div className="color-grey-dark">
                                                <span>{ this.props.labels.newproducts.launchDate }</span>
                                            </div>
                                        </div>
                                        <div className="prop-right name-label">
                                            <div className="color-grey-dark ">
                                                <span>{ this.props.item.lump.date_market }</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <WebsiteRow
                                website={this.props.item.website}
                                actions={this.props.actions} />

                        </div>
                    </div>
                </UseRemainingHeight>
            </div>
        );
    }

}

NewProductContent.propTypes = {
    item        : PropTypes.object,
    isFavorite  : PropTypes.bool,
    isLogoFullWidth: PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    getPageTitle: PropTypes.func.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
    height      : PropTypes.number, // prop set from AppToolbar
};

export default NewProductContent;
