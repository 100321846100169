import { NOTES_PAGE_KEY } from 'src/pages/pagesKeys.js';

import NotesPage        from 'src/pages/notes/NotesPage';
import notesPageReducer from 'src/pages/notes/notesPageReducer';

export default {
    key      : NOTES_PAGE_KEY,
    path     : '/notes',
    component: NotesPage,
    elId     : 'notes-page',
    getReduxReducer: (state, action) => notesPageReducer(state[NOTES_PAGE_KEY], action),
};
