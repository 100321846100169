
import config from 'data/config/config';

import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';
import { ERRORS } from 'src/core/user-data/UserDataService';
import STATUSES from '../statuses';
import dummyToken from './dummyToken';
import { transformData } from './helpers';


const LOG_PREF = '[UserDataWS Taiga] ';


export function getUserData(cb) {
    const url = config.LOGIN.WS_URL+'/tgServices/BlockchainAppWs/me.htm';

    let opts = {
        method: 'GET',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        withCredentials: true, // use cookie (the browser takes care of it)
    };
    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onSuccess(response) {
            switch(response.status) {
                case STATUSES.SUCCESS:
                    cb(
                        null, // error code
                        dummyToken, // token
                        response.datas, // userdata
                    );
                    break;

                case STATUSES.ERROR:
                    // FIXME undocumented
                    cb(ERRORS.UNKNOWN);
                    break;

                default: console.error(LOG_PREF+'Unexpected response status: '+response.status);
            }
        },
        function onFailure(error, message) {
            cb(ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}
if (config.ENV === 'dev') {
    global.getUserDataUsingTaigaWs = getUserData;
}


export function saveUserData(userData, cb) {
    const url = config.LOGIN.WS_URL+'/tgServices/BlockchainAppWs/updateMe.htm';

    let opts = {
        method: 'POST',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
        body: JSON.stringify({ updatedFields: transformData.appToApi.user(userData) }),
        withCredentials: true, // use cookie (the browser takes care of it)
    };
    fetchHelper(
        url,
        opts,
        true, // parse response as json
        function onSuccess(response) {
            if (response.status === 'success') {
                cb(null);
            } else {
                // FIXME undocumented
                cb(ERRORS.UNKNOWN);
            }
        },
        function onFailure(error, message) {
            cb(ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}
if (config.ENV === 'dev') {
    global.saveUserDataUsingTaigaWs = saveUserData;
}
