
import React, { PureComponent } from 'react';
import ReactDOM  from 'react-dom';
import PropTypes from 'prop-types';

import { LIST_GROUPS_PAGE_KEY } from 'src/pages/pagesKeys';

import Loader             from 'src/components/loader/Loader';
import NoResult           from 'src/components/no-result/NoResult';
import List               from 'src/components/list/List';
import TypeBar            from 'src/components/type-bar/TypeBar';
import SideIndex          from 'src/components/side-index/SideIndex';
import AdSwap             from 'src/components/ad-swap/AdSwap';
import IphonexFlexMargin  from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import { simpleSort } from 'src/core/util/sortUtil';


class ListGroupsContent extends PureComponent {

    getGroup = groupKey => this.props.groupedItems[groupKey]

    setSideIndexRef = ref => {
        this.sideIndexRef = ref;
    }

    onScroll = event => {
        if(this.sideIndexRef)
            this.sideIndexRef.setSelectedIndexFromScrollPosition(event.target.scrollTop);
    }

    render() {
        if (!this.props.groupedItems) {

            // No result
            if (this.props.itemNotFound === true) {
                return <NoResult labels={this.props.labels} />;

            // Pending request
            } else if (this.props.isPending === true) {
                return <Loader labels={this.props.labels} />;

            }
            console.error('Unexpected state (no events, and request is neither pending nor without result)');
            return null;
        }


        let separatorEls = {},
            hasSideIndex = true,
            totalItems = 0;

        Object.keys(this.props.groupedItems).forEach(groupId => {
            let group = this.getGroup(groupId);

            // Detect if there is a sideindex
            hasSideIndex = hasSideIndex && !!group.sideIndexLabel;

            // Update total
            if (Array.isArray(group.items)) {
                totalItems += group.items.length;
            }
        });

        let sortedGroupKeys = Object.keys(this.props.groupedItems).sort((group1Key, group2Key) => (
            simpleSort(this.getGroup(group1Key).rank, this.getGroup(group2Key).rank)
        ));

        return (
            <>
                <div onScroll={hasSideIndex && totalItems > this.props.sideIndexThreshold ? this.onScroll : null} className="list-groups-container content-font" style={{ height: this.props.height }}>

                    <div className="items-list" ref={this.props.setContainerEl}>
                        <div className="pos-relative">
                        {
                            sortedGroupKeys.map(groupKey => {
                                let group = this.getGroup(groupKey);
                                return (
                                    <div key={groupKey}>

                                        <TypeBar
                                            isClickable={false}
                                            label={group.name}
                                            subtitle={group.subtitle}
                                            style={group.style}
                                            ref={ ref => { separatorEls[groupKey] = ReactDOM.findDOMNode(ref); } } />

                                        <List
                                            items={group.items}
                                            dataType={this.props.dataType}
                                            isPending={this.props.isPending}
                                            displayFavorites={this.props.favIconDisabled !== true}
                                            favorites={this.props.favorites}
                                            actions={this.props.actions}
                                            labels={this.props.labels}
                                            pageKey={LIST_GROUPS_PAGE_KEY} />
                                    </div>
                                );
                            })
                        }
                        </div>
                    </div>

                    { hasSideIndex && totalItems > this.props.sideIndexThreshold &&
                        <SideIndex
                            ref={this.setSideIndexRef}
                            indexes={sortedGroupKeys}
                            separatorsGetter={() => separatorEls}
                            scrollTo={this.props.scrollTo}
                            getLabel={ groupKey => this.getGroup(groupKey).sideIndexLabel } />
                    }
                </div>

                { this.props.ad &&
                    <AdSwap
                        adKey={this.props.adKey}
                        ad={this.props.ad}
                        isPageVisible={this.props.isPageVisible} /> // ad config from data !
                }

                <IphonexFlexMargin />
            </>
        );
    }
}

ListGroupsContent.propTypes = {
    isPending         : PropTypes.bool,
    itemNotFound      : PropTypes.bool,
    groupedItems      : PropTypes.object,
    dataType          : PropTypes.string,
    favorites         : PropTypes.object,
    setContainerEl    : PropTypes.func.isRequired,
    scrollTo          : PropTypes.func.isRequired,
    sideIndexThreshold: PropTypes.number.isRequired,
    favIconDisabled   : PropTypes.bool,
    adKey             : PropTypes.string,
    ad                : PropTypes.object,
    isPageVisible     : PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
    height      : PropTypes.number, // prop set from AppToolbar
};

export default ListGroupsContent;