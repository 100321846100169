import React from 'react';
import PropTypes from 'prop-types';
import { GestureDetector } from 'onsenui';
import Measure from 'react-measure';

import IphonexFlexMargin from 'src/components/iphonex-flex-margin/IphonexFlexMargin';
import List             from 'src/components/list/List';
import TypeBar          from 'src/components/type-bar/TypeBar';

import './ContextualSearch.scss'

class ContextualSearch extends React.Component {

    state = {}

    getDetail = () => {
        let dataTypes = Object.keys(this.props.items);

        return dataTypes.map(dataType => {

            let items = this.props.items[dataType];
            if (items.length === 0) return null

            const listProps = {
                key              : dataType,
                actions          : this.props.actions,
                labels           : this.props.labels,
                dataType         : dataType,
                favorites        : this.props.favorites,
                displayFavorites : this.props.favIconDisabled !== true,
                height           : this.state.listHeight,
                items            : items,
                onItemClick      : this.props.onItemClick,
            };

            const list = <List {...listProps} />;

            return (
                <TypeBar
                    key={dataType}
                    label={this.props.labels.data[dataType].plural}>
                    {list}
                </TypeBar>
            );
        });
    }

    containerElementDidRender = el => {
        // @see https://onsen.io/v2/docs/guide/js/#adding-page-content  § Gesture detector
        if (el) {
            GestureDetector(el).dispose();
        }
    }

    onDimensionsUpdate = measure => {
        this.setState({ listHeight: measure.client.height });
    }

    isListEmpty = () => {
        const hasItems = this.props.items !== null && typeof this.props.items === 'object';
        const noData = hasItems && (Object.keys(this.props.items).length === 0
            || Object.keys(this.props.items).every(key => !this.props.items[key]
                || (Array.isArray(this.props.items[key]) && this.props.items[key].length === 0)))

        if (!hasItems) return true

        return noData;
    }

    render() {
        const listEmpty = this.isListEmpty()

        return (
            <div className={this.props.className}
                 style={{ height: this.props.height }}
                 ref={this.containerElementDidRender}>

                <Measure client onResize={this.onDimensionsUpdate}>
                    {({ measureRef }) => (
                        <div ref={measureRef} className="list-search-content content-font">
                            {listEmpty && <div className="list-empty">{this.props.labels.common.noData}</div>}
                            {!listEmpty && this.getDetail()}
                        </div>
                    )}
                </Measure>

                <IphonexFlexMargin />
            </div>
        );
    }
}

ContextualSearch.props = {
    className: PropTypes.string,
    items: PropTypes.object,
    actions: PropTypes.object.isRequired,
    labels: PropTypes.object.isRequired,
    height: PropTypes.number
};

export default ContextualSearch;
