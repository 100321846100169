
import {
    APPOINTMENT_REQUEST_SEND_RESULT,
    CONTACT_REQUEST_PERFORMED,
    GEOGROUP_GROUP_CREATED,
    GEOGROUP_GROUP_JOINED,
    GEOGROUP_GROUP_QUITTED,
    GEOGROUP_PSEUDO_SET,
    HAS_NAVIGATED,
    HIDE_INTERSTICIEL,
    LINK_CLICKED,
    MAP_USER_LOCATED,
    MAP_FAVORITE_CREATED,
    MAP_FAVORITE_SHARED,
    MOBIGEO_ERROR_THROWN,
    ITINERARY_API_CALLED,
    NOTE_CREATED,
    NOTE_DELETED,
} from 'src/store/actionTypes';

import { getCurrent as getCurrentLang } from 'src/core/Lang';
import { get as getCurrentProfile }     from 'src/core/Profile';
import { getCurrentQueryString }        from 'src/core/navigation/Router';
import { CATEGORIES,
         ACTIONS,
         sendPageView,
         sendEvent } from 'src/core/analytics/GoogleAnalyticsHelper';


export default ({ dispatch, getState }) => next => action => {

    const result = next(action);

    let cat;
    switch (action.type) {

        case HAS_NAVIGATED:
            sendPageView(getCurrentQueryString()
                            +'&lang='+getCurrentLang()
                            +'&profile='+getCurrentProfile());
            break;


        case HIDE_INTERSTICIEL:
            cat = CATEGORIES.intersticiel;
            sendEvent(cat, ACTIONS[cat].view);
            break;


        case LINK_CLICKED:
            cat = CATEGORIES.linkClicked;
            sendEvent(cat, ACTIONS[cat].url, action.url);
            break;


        case ITINERARY_API_CALLED:
            cat = CATEGORIES.mobigeo;
            sendEvent(cat, ACTIONS[cat].goTo, JSON.stringify(action.destination));
            break;


        case MOBIGEO_ERROR_THROWN:
            cat = CATEGORIES.mobigeo;
            sendEvent(cat, ACTIONS[cat].error, action.module+' '+action.errorCode, action.additionalInfo);
            break;


        case MAP_USER_LOCATED:
            cat = CATEGORIES.mobigeo;
            sendEvent(cat, ACTIONS[cat].enterSite);
            break;

        case MAP_FAVORITE_CREATED:
            cat = CATEGORIES.mobigeo;
            sendEvent(cat, ACTIONS[cat].favoriteCreated);
            break;

        case MAP_FAVORITE_SHARED:
            cat = CATEGORIES.mobigeo;
            sendEvent(cat, ACTIONS[cat].favoriteShared);
            break;

        case GEOGROUP_PSEUDO_SET:
            cat = CATEGORIES.mobigeo;
            sendEvent(cat, ACTIONS[cat].setPseudo);
            break;


        case GEOGROUP_GROUP_CREATED:
            cat = CATEGORIES.mobigeo;
            sendEvent(cat, ACTIONS[cat].createGroup);
            break;


        case GEOGROUP_GROUP_JOINED:
            cat = CATEGORIES.mobigeo;
            sendEvent(cat, ACTIONS[cat].joinGroup);
            break;


        case GEOGROUP_GROUP_QUITTED:
            cat = CATEGORIES.mobigeo;
            sendEvent(cat, ACTIONS[cat].quitGroup);
            break;


        case APPOINTMENT_REQUEST_SEND_RESULT:
            cat = CATEGORIES.appointment;
            sendEvent(
                cat,
                ACTIONS[cat][action.success ? 'success' : 'failure'],
                JSON.stringify(action.status),
                action.dataType+'-'+action.dataOriginalId
            );
            break;

        case NOTE_CREATED:
            cat = CATEGORIES.note;
            sendEvent(cat, ACTIONS[cat].noteCreated);
            break;

        case NOTE_DELETED:
            cat = CATEGORIES.note;
            sendEvent(cat, ACTIONS[cat].noteDeleted);
            break;


        case CONTACT_REQUEST_PERFORMED:
            let { id, dataType, ws, error } = action;

            let cat = CATEGORIES.appointment;
            sendEvent(
                cat,
                ACTIONS[cat][!error ? 'success' : 'failure'],
                id,
                dataType,
            );
            break;


        default: // for linter

    }
    return result;
};
