
import config from 'data/config/config';

import { ERRORS } from 'src/core/login/LoginService';
import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';



export function getUserData(token, cb) {

    let opts = {
        method: 'GET',
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
    };

    fetchHelper(
        `${config.USER_DATA.WS_URL}/${token}`,
        opts,
        true, // parse response as json
        function onSuccess(data) {
            cb(null, data.profile);
        },
        function onFailure(error, message) {
            cb(ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}
