
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import {
    DATA_TYPE_EVENTS,
    DATA_TYPE_SPEAKERS,
    getSpeakerTitle,
    // getSpeakerSubtitle,
} from 'data/config/dataConfig';

import { SPEAKER_PAGE_KEY } from 'src/pages/pagesKeys';

// import DetailCountry      from 'src/components/detail-country/DetailCountry';
// import DetailEvents       from 'src/components/detail-events/DetailEvents';
import DetailFixedTitle   from 'src/components/detail-fixed-title/DetailFixedTitle';
import DetailSocialRaw    from 'src/components/detail-social/DetailSocialRaw';
import UseRemainingHeight from 'src/components/use-remaining-height/UseRemainingHeight';
// import Description        from 'src/components/fiche/Description';
import WebsiteRow         from 'src/components/fiche/WebsiteRow';
import NoteButton         from 'src/components/notes/NoteButton';
import List               from 'src/components/list/List';
// import PhoneRow           from 'src/components/fiche/PhoneRow';
// import EmailRow           from 'src/components/fiche/EmailRow';
// import ShareButton        from 'src/components/share-button/ShareButton';
import { getUrl } from 'src/core/data-and-assets/DataAssetsUtil';

class SpeakerContent extends PureComponent {

    setScroller = ref => {
        // Used by parent component
        this.scroller = ref;
    }

    onClickOnFavIcon = () => {
        this.props.actions.toggleFavorite(
            this.props.item.id,
            DATA_TYPE_SPEAKERS,
            this.props.isFavorite
        );
    }

    showEvents = () => {
        this.props.actions.navigateToItems(
            this.props.item.references[DATA_TYPE_EVENTS],
            DATA_TYPE_EVENTS,
            this.props.item.id,
            DATA_TYPE_SPEAKERS);
    }

    render() {
        const title = getSpeakerTitle(this.props.item);

        return (
            <div className="fiche speaker content-font" style={{ height: this.props.height }}>

                {/* title */}
                <DetailFixedTitle
                    title={title}
                    isFav={this.props.isFavorite}
                    onFavClick={this.onClickOnFavIcon}
                    labels={this.props.labels} >

                    <NoteButton
                          itemTitle={this.props.item.title}
                          itemId={this.props.item.id}
                          dataType={DATA_TYPE_SPEAKERS}
                          hasNote={this.props.hasNote}
                          labels={this.props.labels}
                          actions={this.props.actions} />

                    {/* <ShareButton
                        name={this.props.getPageTitle(this.props.item, this.props.labels)}
                        queryString={this.props.queryString}
                        description={description}
                        image={logoUrl}
                        labels={this.props.labels}
                        actions={this.props.actions}
                        profile={this.props.profile}
                        pageKey={SPEAKER_PAGE_KEY} /> */}

                </DetailFixedTitle>

                <UseRemainingHeight ref={this.setScroller}>
                    <div className="content">
                        <div className="all-informations content-font">

                            <div className="spk-row1">
                                <div className="spk-row1-col1">
                                    <img className="spk-photo" src={ getUrl('/'+this.props.item.photo) } alt="speaker photo" />
                                </div>
                                <div className="spk-row1-col2">
                                    <div className="spk-subtitle"
                                         dangerouslySetInnerHTML={{ __html: this.props.item.lump.subtitle }} />
                                    {
                                        this.props.item.biography && <div className="spk-biography" dangerouslySetInnerHTML={{ __html: this.props.item.biography }} />
                                    }
                                    <DetailSocialRaw
                                        data={this.props.item.lump.social}
                                        actions={this.props.actions}/>

                                    { this.props.item.logo &&
                                        <div className="spk-logo">
                                            <img src={ getUrl('/'+this.props.item.logo) } alt="speaker logo" />
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="spk-row2">
                                <WebsiteRow
                                    website={this.props.item.website}
                                    actions={this.props.actions} />

                                {/*
                                    <PhoneRow phone={this.props.item.phone} />
                                    <EmailRow email={this.props.item.email} />
                                */}

                                { this.props.item.address &&
                                    <div className="free-row">
                                        <div className="prop-img">
                                            <div className="prop-left">
                                                <div className="fiche-contact-icon">
                                                    <span className="fa fa-university" />
                                                </div>
                                            </div>
                                            <div className="prop-right" dangerouslySetInnerHTML={{ __html: this.props.item.address }} />
                                        </div>
                                    </div>
                                }
                            </div>

                            {/* events */}
                            {/* <DetailEvents
                                    items={this.props.item.references.events}
                                    labels={this.props.labels}
                                    onClick={this.showEvents} /> */}
                            { Array.isArray(this.props.item.references.events) && this.props.item.references.events.length > 0 &&
                                <div>
                                    <div className="fiche-separator">{ this.props.labels.speaker.relatedInterventions }</div>
                                    <List
                                        items={ this.props.item.references.events }
                                        dataType={ DATA_TYPE_EVENTS }
                                        actions={ this.props.actions }
                                        labels={ this.props.labels }
                                        isFastAndUgly={ false }
                                        displayFavorites={ false }
                                        pageKey={ SPEAKER_PAGE_KEY } />
                                </div>
                            }
                        </div>
                    </div>
                </UseRemainingHeight>
            </div>
        );
    }
}

SpeakerContent.propTypes = {
    item        : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    isFavorite  : PropTypes.bool,
    isLogoFullWidth: PropTypes.bool,
    // Common
    queryString : PropTypes.string.isRequired,
    getPageTitle: PropTypes.func.isRequired,
    labels      : PropTypes.object.isRequired,
    actions     : PropTypes.object.isRequired,
    height      : PropTypes.number, // prop set from AppToolbar
};

export default SpeakerContent;
