
// Libs
import React, { Component }   from 'react';
import PropTypes              from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { Page }               from 'react-onsenui';

// App modules
import { HOME_PAGE_KEY } from 'src/pages/pagesKeys';
import Menu from 'src/components/menu/Menu';
import { /*hasParentWithClass,*/ getPageHeight } from 'src/core/util/DomTools';
import { /*isIOS,*/ isIphoneX } from 'src/core/util/browser';

import * as actions from 'src/store/actions';

import './HomePage.scss';


const LOG_PREF = '[HomePage] ';

const CONTENT_PADDING = {
    top: 5,
    right: 3,
    bottom: 0,
    left: 3,
};


class HomePage extends Component {

    constructor(props) {
        super(props);
        this.pageKey = HOME_PAGE_KEY;
    };

    /**
     * Update string visible in browser tab/history/favorites
     */
    setDocumentContext() {
        if (this.props.isActive(this.pageKey)) {
            this.props.setDocumentContext(this.getPageTitle());
        }
    }

    /**
     * String displayed in app toolbar
     * @return {string}
     */
    getPageTitle() {
        return this.props.labels.home.title;
    }

    componentDidMount() {
        this.setDocumentContext();
    }
    componentDidUpdate() {
        this.setDocumentContext();
    }

    /**
     * prevent overscroll on iOS
     * @param  {object} e
     */
    onTouchStartHandler(e) {
        /*if (isIOS() && (document.documentElement.clientHeight > 500 || hasParentWithClass(e.target, 'home-tiles') === false)) {
            e.preventDefault();
        }*/
    }

    getContent(props) {
        if (Array.isArray(props.homeConfig) !== true) {
            return null;
        }
        return props.homeConfig.map((entry, index) => {
            let entryProps = entry.props || {};
            entryProps.inboxCount = this.props.inboxUnreadCount;
            return <entry.component
                        key={index}
                        {...entryProps}
                        lang={props.labels.id}
                        labels={props.labels}
                        actions={props.actions}
                        profile={props.profile}
                        isLoggedIn={props.isLoggedIn}
                        associatedPageKey={this.pageKey}
                        adConfig={props.adConfig}
                        isPageVisible={props.isVisible} />;
        });
    }

    render() {
        console.log(LOG_PREF+'render');

        // Keep rendering (avoid ad "glitch" rendering)
        // if (!this.props.isVisible) return null;

        const homeContentStyle = Object.assign({
            height: getPageHeight() - (CONTENT_PADDING.top+CONTENT_PADDING.bottom) + (isIphoneX() && global.isCordovaContext ? -28 : 0),
            padding: CONTENT_PADDING.top+'px '
                   + CONTENT_PADDING.right+'px '
                   + CONTENT_PADDING.bottom+'px '
                   + CONTENT_PADDING.left+'px',
        }, this.props.homeStyle || {});

        return (
            <Menu
                options={{ isOpen: this.props.isMenuOpen }}
                actions={this.props.actions}
                labels={this.props.labels}
                profile={this.props.profile}
                associatedPageKey={this.pageKey}
                adConfig={this.props.adConfig}
                twoColumns={this.props.twocolumns}
                isLocationEnabled={this.props.isLocationEnabled}>

                <Page onTouchStart={this.onTouchStartHandler} contentStyle={{ background: 'none' }}>
                    <div id="home-content"
                         className="content-font"
                         style={homeContentStyle}
                         >
                        { this.getContent(this.props) }
                    </div>
                </Page>
            </Menu>
        );
    };
}

HomePage.propTypes = {
    homeConfig : PropTypes.array.isRequired,
    homeStyle  : PropTypes.object,
    adConfig   : PropTypes.object,
    isLoggedIn : PropTypes.bool,
    // Common page props
    isMenuOpen        : PropTypes.bool.isRequired,
    profile           : PropTypes.string,
    labels            : PropTypes.object.isRequired,
    actions           : PropTypes.object.isRequired,
    isActive          : PropTypes.func.isRequired,
    setDocumentContext: PropTypes.func.isRequired,
    isLocationEnabled : PropTypes.bool,
};

const mapStateToProps = (state, ownProps) => state[HOME_PAGE_KEY];
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage);
