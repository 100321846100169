
import config from 'data/config/config';

import { ERRORS } from 'src/core/login/LoginService';
import fetchHelper, { HEADERS } from 'src/core/util/FetchHelper';


export function performLogin(account, cb) {

    let opts = {
        method: 'POST',
        body: JSON.stringify(account),
        headers: [ HEADERS.JSON_CONTENT_TYPE ],
    };
    fetchHelper(
        config.LOGIN.WS_URL,
        opts,
        true, // parse response as json
        function onSuccess(data) {
            if (data.success) {
                cb(
                    null, // error code
                    data.identification || data.token, // token
                    data.profile, // userdata
                );

            } else {
                cb(
                    ERRORS.LOGIN, // error code
                    null, // token
                );
            }
        },
        function onFailure(error, message) {
            cb(ERRORS.NETWORK);
        },
        false // showModalOnError
    );
}
