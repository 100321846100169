
import * as localforage from 'localforage';

import { INBOX_PAGE_KEY } from 'src/pages/pagesKeys';

import {
    ADD_BULK_MESSAGES,
    ADD_BULK_MESSAGE_ERROR,
    ADD_MESSAGE,
    ADD_DUPLICATE_MESSAGE,
    MESSAGES_TYPES,
    getNotifAbility,
} from './inboxReducer';

import { parseAction } from 'src/store/reducers/utils';

import { DB_CONFIG_ENDPOINT, DB_ENDPOINT } from './inboxReducer';

import { getCurrent as getCurrentLang } from 'src/core/Lang';


const LOG_PREF = '[InboxService] ';

const DEFAULT_TITLE = 'MESSAGE';


let store;

export function init(_store) {
    store = _store;

    // for dev only
    // demonstrates the process of opening a subscription
    // wihtin a middleware to dispatch directly inside the store
    /*if ( process.env.NODE_ENV !== 'production' || global.isCordovaContext ) {

        const dummies = [
            null,
            'service/517',
            'aircraft/16',
            'exhibitor/372605',
            'event/382',
            'newproduct/1165',
            'media',
            'pmr',
            // 'flights_schedule/3', this case in not taken into account for now
            'flights_schedule',
            'service/514',
            'https://www.siae.fr/evenements/paris_air_lab.htm',
            'map/pois?originalId=311571&type=Exhibitor',
            'map/pois?originalId=366494&type=Exhibitor&placeId=193',
        ].map(parseAction);

        let nextActionId = 0;

        const shortNotif = 'a short notif';
        const longNotif =  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras elementum ultrices '

        global.addMessage = function addMessage () {
            return store.dispatch({
                type: ADD_MESSAGE,
                data: {
                    id: nextActionId++,
                    content: nextActionId % 2 ? longNotif : shortNotif,
                    title: 'A notification',
                    date: Date.now(),
                    read: false,
                    action: dummies[nextActionId % dummies.length]
                },
                meta: {
                    type: MESSAGES_TYPES.debug,
                    hasNotifAbility: getNotifAbility(store.getState().Cordova)
                }
            });
        }
    }*/

    // Retrieve messages from the db and returns them as an action
    localforage
        .getItem(DB_ENDPOINT)
        .then( (messages = {
            byId: {},
            byDate: []
        }) => {
            if (typeof messages !== 'object' || messages === null) {
                throw new TypeError('values stored in the DB are not valid messages');
            } else {
                store.dispatch({
                    type: ADD_BULK_MESSAGES,
                    data: messages,
                    meta: {
                        // adds a 'meta' key to give context to this action
                        // in this case whether the env can display the message itself
                        // or if it should be done within the app
                        hasNotifAbility: getNotifAbility(store.getState().Cordova)
                    }
                });
            }
        })
        .catch(err => store.dispatch({
            type: ADD_BULK_MESSAGE_ERROR,
            data: err
        }));


    // check if config is still relevant
    localforage
        .getItem(DB_CONFIG_ENDPOINT)
        .then(db_endpoint => {

            if (db_endpoint !== DB_ENDPOINT) {

                // replace old value with new one
                localforage.setItem(DB_CONFIG_ENDPOINT, DB_ENDPOINT)
                    .then(
                        () => console.log(LOG_PREF+'Successfully deleted old config'),
                        error => console.error(LOG_PREF+'Could not delete old config due to error ' + error)
                    );
            }
        });
}


/**
 * Trying to understand and simplify the overly complicated
 * inboxReducer.createNotificationHandler function
 *
 * @param  {object} store
 * @param  {object} notif
 */
export function notificationHandler(notif) {

    // PushWoosh notifications
    /* android format:
    {
      "onStart": false,
      "pw_msg": "1",
      "p": "7y",
      "userdata": {
        "page": "Aircraft",
        "originalId": "101"
      },
      "u": "{ \"page\": \"Aircraft\", \"originalId\": \"101\" }",
      "pri": "",
      "vib": "0",
      "title": "test push android n°x1 (action aircraft)",
      "foreground": true
    }
    // iOS format:
    {
      "foreground": true,
      "onStart": false,
      "userdata": { "page":"Exhibitor", "originalId":"375160" },
      "ios":{
        "p":"7X",
        "u": stringified user data
        "pw_push":true,
        "aps": { "sound":"default", "alert":"test pushwoosh iOS 3 (action EXH DELTAPLAST)" }
      },
      "message": "test pushwoosh iOS 3 (action EXH DELTAPLAST)"
    }*/

    // Geopush notifications (NAO):
    /*{
      "id": 37166,
      "title": {
        "fr": "Géonotif",
        "en": "Geonotification"
      },
      "text": {
        "fr": "Bienvenue au Hall3 Ouest où se trouve Eurofighter Typhoon",
        "en": "Welcome to the Hall 3 West where you can see the Eurofighter Typhoon"
      },
      "action": {
        "page": "Aircraft",
        "originalId": "101"
      },
      "name": "Hall3W"
    }*/

    let currentLang = getCurrentLang(),
        notifId = notif.id || new Date().getTime();

    const inboxActionType = typeof store.getState().Inbox.byId[notifId] === 'object'
                            ? ADD_DUPLICATE_MESSAGE
                            : ADD_MESSAGE;

    let action;
    try {
        action = parseAction(notif.action || notif.userdata || notif.customData);
    } catch (e) {
        action = { page: INBOX_PAGE_KEY };
        console.error('could not parse action', e);
    }

    let title;
    // Geopush
    if (typeof notif.title === 'object' && notif.title !== null && notif.title[currentLang]) {
        title = notif.title[currentLang];
    } else {
        title = DEFAULT_TITLE;
    }

    let content;
    // Geopush
    if (typeof notif.text === 'object' && notif.text !== null && notif.text[currentLang]) {
        content = notif.text[currentLang];
    }
    // Web push
    else if (typeof notif.body === 'string') {
        content = notif.body;
    }
    // Pushwoosh (android)
    else if (typeof notif.title === 'string') {
        content = notif.title;
    }
    // Pushwoosh (iOS)
    else if (typeof notif.message === 'string') {
        content = notif.message;
    }

    store.dispatch({
        type: inboxActionType,
        data: {
            id     : notifId,
            title  : title,
            content: content,
            action : action,
            date   : Date.now(),
        },
        meta: {
            hasNotifAbility: getNotifAbility(store.getState().Cordova),
            type: MESSAGES_TYPES.notification,
        }
    });
}

if (process.env.NODE_ENV !== 'production') {
    global.notificationHandler = notificationHandler;
}